import { ActionType } from "typesafe-actions";
import {
  APISearchCriteriaResponseBody,
  FlattenedSearchState,
} from "../search/types";
import * as CriteriaActions from "./actions";

export const FETCH_LEGAL_FORMS = "FETCH_LEGAL_FORMS";
export const FETCH_LEGAL_FORMS_SUCCESS = "FETCH_LEGAL_FORMS_SUCCESS";
export const FETCH_LEGAL_FORMS_ERROR = "FETCH_LEGAL_FORMS_ERROR";
export const CLEAR_HAS_CRITERIA_CHANGED = "CLEAR_HAS_CRITERIA_CHANGED";

export const FETCH_SAVED_SEARCHES = "FETCH_SAVED_SEARCHES";
export const FETCH_SAVED_SEARCHES_SUCCESS = "FETCH_SAVED_SEARCHES_SUCCESS";
export const FETCH_SAVED_SEARCHES_ERROR = "FETCH_SAVED_SEARCHES_ERROR";

export const SAVE_SEARCH = "SAVE_SEARCH";
export const SAVE_SEARCH_SUCCESS = "SAVE_SEARCH_SUCCESS";
export const SAVE_SEARCH_ERROR = "SAVE_SEARCH_ERROR";
export const SAVE_SEARCH_CLEAR = "SAVE_SEARCH_CLEAR";

export const DELETE_SAVED_SEARCH = "DELETE_SAVED_SEARCH";
export const DELETE_SAVED_SEARCH_SUCCESS = "DELETE_SAVED_SEARCH_SUCCESS";

export const LOAD_SAVED_SEARCH = "LOAD_SAVED_SEARCH";
export const CLEAR_SAVED_SEARCHES = "CLEAR_SAVED_SEARCHES";

export const FETCH_LOCATIONS_BY_NAME = "FETCH_LOCATIONS_BY_NAME";
export const FETCH_LOCATIONS_BY_NAME_SUCCESS =
  "FETCH_LOCATIONS_BY_NAME_SUCCESS";
export const FETCH_LOCATIONS_BY_NAME_ERROR = "FETCH_LOCATIONS_BY_NAME_ERROR";

export const FETCH_LOCATIONS_BY_PARENT = "FETCH_LOCATIONS_BY_PARENT";
export const FETCH_LOCATIONS_BY_PARENT_SUCCESS =
  "FETCH_LOCATIONS_BY_PARENT_SUCCESS";
export const FETCH_LOCATIONS_BY_PARENT_ERROR =
  "FETCH_LOCATIONS_BY_PARENT_ERROR";

export const FETCH_ACTIVITY_TYPE = "FETCH_ACTIVITY_TYPE";
export const FETCH_ACTIVITY_TYPE_SUCCESS = "FETCH_ACTIVITY_TYPE_SUCCESS";
export const FETCH_ACTIVITY_TYPE_ERROR = "FETCH_ACTIVITY_TYPE_ERROR";

export const FETCH_ACTIVITY_CODES_BY_ROOT = "FETCH_ACTIVITY_CODES_BY_ROOT";
export const FETCH_ACTIVITY_CODES_BY_ROOT_SUCCESS =
  "FETCH_ACTIVITY_CODES_BY_ROOT_SUCCESS";
export const FETCH_ACTIVITY_CODES_BY_ROOT_ERROR =
  "FETCH_ACTIVITY_CODES_BY_ROOT_ERROR";

export const FETCH_ACTIVITY_CODES_BY_PARENT = "FETCH_ACTIVITY_CODES_BY_PARENT";
export const FETCH_ACTIVITY_CODES_BY_PARENT_SUCCESS =
  "FETCH_ACTIVITY_CODES_BY_PARENT_SUCCESS";
export const FETCH_ACTIVITY_CODES_BY_PARENT_ERROR =
  "FETCH_ACTIVITY_CODES_BY_PARENT_ERROR";

export const FETCH_ACTIVITY_CODES_BY_NAME = "FETCH_ACTIVITY_CODES_BY_NAME";
export const FETCH_ACTIVITY_CODES_BY_NAME_SUCCESS =
  "FETCH_ACTIVITY_CODES_BY_NAME_SUCCESS";
export const FETCH_ACTIVITY_CODES_BY_NAME_ERROR =
  "FETCH_ACTIVITY_CODES_BY_NAME_ERROR";

export const FETCH_BUSINESS_CLASSIFICATIONS = "FETCH_BUSINESS_CLASSIFICATIONS";
export const FETCH_BUSINESS_CLASSIFICATIONS_SUCCESS =
  "FETCH_BUSINESS_CLASSIFICATIONS_SUCCESS";
export const FETCH_BUSINESS_CLASSIFICATIONS_ERROR =
  "FETCH_BUSINESS_CLASSIFICATIONS_ERROR";

export const FETCH_COMPANY_AUDITORS = "FETCH_COMPANY_AUDITORS";
export const FETCH_COMPANY_AUDITORS_SUCCESS = "FETCH_COMPANY_AUDITORS_SUCCESS";
export const FETCH_COMPANY_AUDITORS_ERROR = "FETCH_COMPANY_AUDITORS_ERROR";

export interface Location {
  key: string;
}

export interface LocationState {
  all: Location[];
  isLoading: boolean;
  hasError: boolean;
}

export interface ActivityCodeSearchBody {
  code: string;
}

export interface ActivityCode {
  code: string;
  activityType: string;
  parentPath: string;
  isParent: boolean;
}

export interface ActivityCodeState {
  all: ActivityCode[];
  isLoading: boolean;
  hasError: boolean;
}

export type SavedSearch = {
  id: string;
  name: string;
  description: string;
  createdAt: Date;
  searchCriteria: APISearchCriteriaResponseBody;
  isDisabled?: boolean;
};

export interface CriteriaState {
  legalForms: string[];
  isLoadingLegalForms: boolean;
  hasErrorLegalForms: boolean;
  businessClassifications: string[];
  isLoadingBusinessClassifications: boolean;
  hasErrorBusinessClassifications: boolean;
  companyAuditors: string[];
  isLoadingCompanyAuditors: boolean;
  hasErrorCompanyAuditors: boolean;
  savedSearches: SavedSearch[];
  totalSavedSearches: number;
  isLoadingSavedSearches: boolean;
  isSavingSearch: boolean;
  searchSaved: boolean;
  hasErrorSaveSearch: boolean;
  hasCriteriaChanged: boolean;
  locations: {
    byName: LocationState;
    byParent: LocationState;
  };
  activityCodes: {
    type: {
      isLoading: boolean;
      hasError: boolean;
    };
    byName: ActivityCodeState;
    byParent: ActivityCodeState;
    byRoot: ActivityCodeState;
  };
}

export type FetchLegalFormPayload = {
  country: string;
};

export type FetchLegalFormsResponse = {
  results: string[];
};

export type FetchBusinessClassificationPayload = {
  country: string;
  registeredType: string;
};

export type FetchBusinessClassificationResponse = {
  results: string[];
};

export type FetchCompanyAuditorPayload = {
  country: string;
};

export type FetchCompanyAuditorResponse = {
  results: string[];
};

export type FetchSavedSearchesPayload = {
  page: number;
  limit: number;
};

export type FetchSavedSearchesResponse = {
  savedSearches: SavedSearch[];
  total: number;
};

export type DeleteSavedSearchPayload = {
  savedSearchId: string;
};

export type LoadSavedSearchPayload = {
  searchCriteria: APISearchCriteriaResponseBody;
};

export type SaveSearchPayload = {
  name: string;
  description?: string;
  searchCriteria: FlattenedSearchState;
};

export type FetchLocationsPayload = {
  key: string;
  limit?: number;
};

export type FetchActivityTypePayload = {
  country: string;
};

export type FetchActivityTypeResponse = {
  type: string;
};

export type FetchActivityCodesByParentPayload = {
  activityType: string;
  parentId: string;
};

export type FetchActivityCodesByNamePayload = {
  activityType: string;
  name: string;
  limit: number;
  lang: string;
};

export type FetchActivityCodesResponse = ActivityCode[];

export type FetchLocationsResponse = Location[];

export type CriteriaActionTypes = ActionType<typeof CriteriaActions>;
