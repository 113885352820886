import { Epic } from "../rootEpic";
import { of } from "rxjs";
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  switchMap,
} from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";
import { mapSearchCriteriaToApiPayload } from "../../utils/searchCriteriaMapping";
import { IConfig } from "../../config";
import { DEFAULT_LIMIT, DEFAULT_PAGE } from "../../constants";
import { RequestType } from "../epicDependencies";
import { getTotalCountHeader } from "../utils/getTotalCountHeader";
import {
  deleteSavedSearchSuccess,
  fetchActivityCodesByNameError,
  fetchActivityCodesByNameSuccess,
  fetchActivityCodesByParentError,
  fetchActivityCodesByParentSuccess,
  fetchActivityCodesByRootError,
  fetchActivityCodesByRootSuccess,
  fetchActivityTypeError,
  fetchActivityTypeSuccess,
  fetchBusinessClassificationsError,
  fetchBusinessClassificationsSuccess,
  fetchCompanyAuditorsError,
  fetchCompanyAuditorsSuccess,
  fetchLegalFormsError,
  fetchLegalFormsSuccess,
  fetchLocationsByNameError,
  fetchLocationsByNameSuccess,
  fetchLocationsByParentError,
  fetchLocationsByParentSuccess,
  fetchSavedSearchesError,
  fetchSavedSearchesSuccess,
  saveSearchError,
  saveSearchSuccess,
} from "./actions";
import {
  FETCH_BUSINESS_CLASSIFICATIONS,
  DELETE_SAVED_SEARCH,
  DELETE_SAVED_SEARCH_SUCCESS,
  FetchActivityCodesByParentPayload,
  FETCH_ACTIVITY_CODES_BY_NAME,
  FETCH_ACTIVITY_CODES_BY_PARENT,
  FETCH_ACTIVITY_CODES_BY_ROOT,
  FETCH_ACTIVITY_TYPE,
  FETCH_LEGAL_FORMS,
  FETCH_LOCATIONS_BY_NAME,
  FETCH_LOCATIONS_BY_PARENT,
  FETCH_SAVED_SEARCHES,
  SAVE_SEARCH,
  FETCH_COMPANY_AUDITORS,
} from "./types";

export const fetchLegalFormsEpic: Epic<
  | ActionType<typeof fetchLegalFormsSuccess>
  | ActionType<typeof fetchLegalFormsError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_LEGAL_FORMS)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/criterias/_metaData/legalForm?country=${payload.country}`,
      }).pipe(
        switchMap(({ response }) =>
          of(fetchLegalFormsSuccess({ results: response }))
        ),
        catchError(() => of(fetchLegalFormsError()))
      )
    )
  );

export const fetchBusinessClassificationsEpic: Epic<
  | ActionType<typeof fetchBusinessClassificationsSuccess>
  | ActionType<typeof fetchBusinessClassificationsError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_BUSINESS_CLASSIFICATIONS)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/criterias/_metaData/bussinessClassification?country=${payload.country}&registeredType=${payload.registeredType}`,
      }).pipe(
        switchMap(({ response }) =>
          of(fetchBusinessClassificationsSuccess({ results: response }))
        ),
        catchError(() => of(fetchBusinessClassificationsError()))
      )
    )
  );

export const fetchCompanyAuditorsEpic: Epic<
  | ActionType<typeof fetchCompanyAuditorsSuccess>
  | ActionType<typeof fetchCompanyAuditorsError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_COMPANY_AUDITORS)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/criterias/_metaData/getCompanyAuditor?country=${payload.country}`,
      }).pipe(
        switchMap(({ response }) =>
          of(fetchCompanyAuditorsSuccess({ results: response }))
        ),
        catchError(() => of(fetchCompanyAuditorsError()))
      )
    )
  );

export const fetchLocationsByNameEpic: Epic<
  | ActionType<typeof fetchLocationsByNameSuccess>
  | ActionType<typeof fetchLocationsByNameError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_LOCATIONS_BY_NAME)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/criterias/_metaData/locations/_search?name=${payload.key}&limit=${payload.limit}`,
      }).pipe(
        switchMap(({ response }) => of(fetchLocationsByNameSuccess(response))),
        catchError(() => of(fetchLocationsByNameError()))
      )
    )
  );

export const fetchLocationsByParentEpic: Epic<
  | ActionType<typeof fetchLocationsByParentSuccess>
  | ActionType<typeof fetchLocationsByParentError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_LOCATIONS_BY_PARENT)),
    concatMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/criterias/_metaData/locations?parentId=${payload.key}`,
      }).pipe(
        switchMap(({ response }) =>
          of(fetchLocationsByParentSuccess(response))
        ),
        catchError(() => of(fetchLocationsByParentError()))
      )
    )
  );

export const fetchActivityTypeEpic: Epic<
  | ActionType<typeof fetchActivityTypeSuccess>
  | ActionType<typeof fetchActivityTypeError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_ACTIVITY_TYPE)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/criterias/_metaData/activityType?country=${payload.country}`,
      }).pipe(
        switchMap(({ response }) => of(fetchActivityTypeSuccess(response))),
        catchError(() => of(fetchActivityTypeError()))
      )
    )
  );

export const fetchActivityCodesByRootEpic: Epic<
  | ActionType<typeof fetchActivityCodesByRootSuccess>
  | ActionType<typeof fetchActivityCodesByRootError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_ACTIVITY_CODES_BY_ROOT)),
    concatMap(({ payload }) =>
      fetchActivityCodesByParent(request, config, payload).pipe(
        map(({ response }) => fetchActivityCodesByRootSuccess(response)),
        catchError(() => of(fetchActivityCodesByRootError()))
      )
    )
  );

export const fetchActivityCodesByParentEpic: Epic<
  | ActionType<typeof fetchActivityCodesByParentSuccess>
  | ActionType<typeof fetchActivityCodesByParentError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_ACTIVITY_CODES_BY_PARENT)),
    concatMap(({ payload }) =>
      fetchActivityCodesByParent(request, config, payload).pipe(
        map(({ response }) => fetchActivityCodesByParentSuccess(response)),
        catchError(() => of(fetchActivityCodesByParentError()))
      )
    )
  );

export const fetchActivityCodesByNameEpic: Epic<
  | ActionType<typeof fetchActivityCodesByNameSuccess>
  | ActionType<typeof fetchActivityCodesByNameError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_ACTIVITY_CODES_BY_NAME)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/criterias/_metaData/activityCodes/_search?activityType=${payload.activityType}&name=${payload.name}&lang=${payload.lang}&limit=${payload.limit}`,
      }).pipe(
        switchMap(({ response }) =>
          of(fetchActivityCodesByNameSuccess(response))
        ),
        catchError(() => of(fetchActivityCodesByNameError()))
      )
    )
  );

export const fetchSavedSearchesEpic: Epic<
  | ActionType<typeof fetchSavedSearchesSuccess>
  | ActionType<typeof fetchSavedSearchesError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType([FETCH_SAVED_SEARCHES, DELETE_SAVED_SEARCH_SUCCESS])),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/criterias?page=${
          payload?.page || DEFAULT_PAGE + 1
        }&limit=${payload?.limit || DEFAULT_LIMIT}`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }).pipe(
        map((response) => {
          const count = getTotalCountHeader(response);
          const results = response.response;
          return fetchSavedSearchesSuccess({
            savedSearches: results,
            total: parseInt(count, 10),
          });
        }),
        catchError(() => of(fetchSavedSearchesError()))
      )
    )
  );

export const deleteSavedSearchEpic: Epic<
  ActionType<typeof deleteSavedSearchSuccess>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(DELETE_SAVED_SEARCH)),
    exhaustMap(({ payload }) =>
      request({
        method: "DELETE",
        url: `${config.PROSPECTS_API_URL}/criterias/${payload.savedSearchId}`,
      }).pipe(
        switchMap(() => of(deleteSavedSearchSuccess())),
        catchError(() => of(deleteSavedSearchSuccess()))
      )
    )
  );

export const saveSearchEpic: Epic<
  ActionType<typeof saveSearchSuccess> | ActionType<typeof saveSearchError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(SAVE_SEARCH)),
    exhaustMap(({ payload }) =>
      request({
        method: "POST",
        url: `${config.PROSPECTS_API_URL}/criterias`,
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          name: payload.name,
          description: payload.description,
          searchCriteria: mapSearchCriteriaToApiPayload(payload.searchCriteria),
        },
      }).pipe(
        map(() => saveSearchSuccess()),
        catchError(() => of(saveSearchError()))
      )
    )
  );

const fetchActivityCodesByParent = (
  request: RequestType,
  config: IConfig,
  payload: FetchActivityCodesByParentPayload
) =>
  request({
    method: "GET",
    url: `${config.PROSPECTS_API_URL}/criterias/_metaData/activityCodes?activityType=${payload.activityType}&parentId=${payload.parentId}`,
  });
