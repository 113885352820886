import { intersection } from "lodash";
import { expandHierarchyKey, parseLocationItemKey } from "../appUtils";

export const removeKey = (a: string[], key: string) =>
  a.filter((v: string) => v !== key);

export const removeKeyAndChildren = (a: string[], key: string) =>
  a.filter((x) => !x.startsWith(key));

export const getParentLocations = (key: string): string[] =>
  expandHierarchyKey(key).slice(1, -1);

export const hasParentIncluded = (key: string, allKeys: string[]): boolean =>
  intersection(getParentLocations(key), allKeys).length > 0;

export const isUnnecessaryExclusion = (key: string, included: string[]) => {
  const { country, parentId } = parseLocationItemKey(key);
  const parents = getParentLocations(key);
  const validIncludes = included.filter((x, _, a) => !hasParentIncluded(x, a));

  const isSibling = (k: string) =>
    k.startsWith(parentId) && k !== country && parentId !== k;

  if (
    ![country, ...parents].some((x) =>
      validIncludes.some((y) => y.startsWith(x) && y !== country)
    )
  ) {
    return false;
  }

  return (
    validIncludes.some((x) => isSibling(x)) ||
    !parents.some((x) => validIncludes.includes(x))
  );
};

export const isUnnecessaryExclusionForActivityCode = (
  key: string,
  included: string[]
) => {
  const splittedKey = key.split("/");
  const parentId = splittedKey.slice(0, splittedKey.length - 1).join("/");
  let isParentIncluded = false;
  let isSiblingIncluded = false;
  let parent = key;
  if (included?.length > 0) {
    splittedKey.forEach(() => {
      if (isParentIncluded) return;
      else {
        parent = parent.substring(0, parent.lastIndexOf("/"));
        isParentIncluded = included.includes(parent);
      }
    });

    included.forEach((alreadyIncludedKey: string) => {
      if (isSiblingIncluded) return;
      else {
        const includedKey = alreadyIncludedKey.split("/");
        const includedKeyParentId = includedKey
          .slice(0, includedKey.length - 1)
          .join("/");
        isSiblingIncluded =
          includedKey.length === splittedKey.length &&
          parentId == includedKeyParentId;
      }
    });
  }

  return !isParentIncluded && isSiblingIncluded;
};

export const hasActivityCodeParentIncluded = (
  key: string,
  included: string[]
): boolean => {
  const splittedKey = key.split("/");
  let isParentIncluded = false;
  let parent = key;
  if (included?.length > 0)
    splittedKey.forEach(() => {
      if (isParentIncluded) return;
      else {
        parent = parent.substring(0, parent.lastIndexOf("/"));
        isParentIncluded = included.includes(parent);
      }
    });

  return isParentIncluded;
};
