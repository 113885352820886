import {
  AdminActionTypes,
  AdminState,
  FETCH_SAFENUMBERS,
  FETCH_SAFENUMBERS_ERROR,
  FETCH_SAFENUMBERS_SUCCESS,
  RESET_STATUS,
  Status,
  UPDATE_SAFENUMBERS,
  UPDATE_SAFENUMBERS_ERROR,
  UPDATE_SAFENUMBERS_SUCCESS,
} from "./types";
export const initialState: AdminState = {
  safeNumbers: { data: [], status: Status.unset, errMsg: "" },
  saveStatus: Status.unset,
};

export default function (
  state = initialState,
  action: AdminActionTypes
): AdminState {
  switch (action.type) {
    case FETCH_SAFENUMBERS: {
      return {
        ...state,
        safeNumbers: {
          data: [],
          status: Status.loading,
          errMsg: "",
        },
      };
    }
    case FETCH_SAFENUMBERS_SUCCESS: {
      return {
        ...state,
        safeNumbers: {
          data: action.payload,
          status: Status.loaded,
          errMsg: "",
        },
      };
    }
    case FETCH_SAFENUMBERS_ERROR: {
      return {
        ...state,
        safeNumbers: {
          data: [],
          status: Status.loadingError,
          errMsg: "Loading Error",
        },
      };
    }
    case UPDATE_SAFENUMBERS: {
      return {
        ...state,
        saveStatus: Status.saving,
      };
    }
    case UPDATE_SAFENUMBERS_SUCCESS: {
      return {
        ...state,
        saveStatus: Status.saved,
      };
    }
    case UPDATE_SAFENUMBERS_ERROR: {
      return {
        ...state,
        saveStatus: Status.savingError,
      };
    }
    case RESET_STATUS: {
      return {
        ...state,
        saveStatus: Status.unset,
      };
    }

    default:
      return state;
  }
}
