import { action } from "typesafe-actions";
import {
  CreateJobErrors,
  CreateJobPayload,
  CLEAR_MAPPING_JOB,
  CREATE_JOB,
  CREATE_JOB_ERROR,
  CREATE_JOB_SUCCESS,
  FetchJobsPayload,
  FetchJobsResponse,
  FETCH_JOBS,
  FETCH_JOBS_ERROR,
  FETCH_JOBS_SUCCESS,
  FETCH_JOB_DETAILS,
  FETCH_JOB_DETAILS_ERROR,
  FETCH_JOB_DETAILS_SUCCESS,
  SET_MAPPING_JOB,
  Job,
  FetchJobPayload,
  FETCH_JOB_SAMPLE,
  FETCH_JOB_SAMPLE_SUCCESS,
  FETCH_JOB_SAMPLE_ERROR,
  FetchJobSamplePayload,
  FetchJobSampleResponse,
  SAVE_JOB_MAPPINGS_ERROR,
  SAVE_JOB_MAPPINGS_SUCCESS,
  SAVE_JOB_MAPPINGS,
  SUBMIT_JOB,
  SUBMIT_JOB_SUCCESS,
  SUBMIT_JOB_ERROR,
  UpdateJobMappingsPayload,
} from "./types";

export const fetchJobs = (payload: FetchJobsPayload) =>
  action(FETCH_JOBS, payload);

export const fetchJobsSuccess = (payload: FetchJobsResponse) => {
  return action(FETCH_JOBS_SUCCESS, payload);
};

export const fetchJobsError = () => action(FETCH_JOBS_ERROR);

export const createJob = (payload: CreateJobPayload) =>
  action(CREATE_JOB, payload);

export const createJobSuccess = () => action(CREATE_JOB_SUCCESS);

export const createJobError = (payload: CreateJobErrors) =>
  action(CREATE_JOB_ERROR, payload);

export const setMappingJob = (job: Job) => action(SET_MAPPING_JOB, job);

export const clearMappingJob = () => action(CLEAR_MAPPING_JOB);

export const fetchJobDetails = (payload: FetchJobPayload) =>
  action(FETCH_JOB_DETAILS, payload);

export const fetchJobDetailsSuccess = (payload: Job) => {
  return action(FETCH_JOB_DETAILS_SUCCESS, payload);
};

export const fetchJobDetailsError = () => action(FETCH_JOB_DETAILS_ERROR);

export const fetchJobSample = (payload: FetchJobSamplePayload) =>
  action(FETCH_JOB_SAMPLE, payload);

export const fetchJobSampleSuccess = (payload: FetchJobSampleResponse) => {
  return action(FETCH_JOB_SAMPLE_SUCCESS, payload);
};

export const fetchJobSampleError = () => action(FETCH_JOB_SAMPLE_ERROR);

export const saveJobMappings = (payload: UpdateJobMappingsPayload) =>
  action(SAVE_JOB_MAPPINGS, payload);

export const saveJobMappingsSuccess = (payload: FetchJobPayload) =>
  action(SAVE_JOB_MAPPINGS_SUCCESS, payload);

export const saveJobMappingsError = () => action(SAVE_JOB_MAPPINGS_ERROR);

export const submitJob = (payload: FetchJobPayload) =>
  action(SUBMIT_JOB, payload);

export const submitJobSuccess = (payload: FetchJobPayload) =>
  action(SUBMIT_JOB_SUCCESS, payload);

export const submitJobError = () => action(SUBMIT_JOB_ERROR);
