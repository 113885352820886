export type EnvironmentNames = "local" | "test" | "stage" | "production";
export interface IEnvironment {
  name: EnvironmentNames;
  version: string;
}

declare global {
  interface Window {
    environment: IEnvironment;
    digitalData: Record<string, unknown>;
  }
}

export interface IConfig {
  INSTRUMENTATION_KEY: string;
  PROSPECTS_API_URL: string;
  DATA_CLEANING_API_URL: string;
  ADOBE_ANALYTICS_SCRIPT_URL: string;
  UPP_API_URL: string;
  UPP_APP_URL: string;
  UPP_UBO_URL: string;
  LINKAGES_ROUTER_URL: string;
  PORTFOLIOS_API_URL: string;
  DEBUG_MODE: boolean;
  LOCIZE_ENABLED: boolean;
  WALKME_SRC: string;
  SPLITIO_KEY: string;
}

const initConfig = (isAzure = false): IConfig => {
  const envName = window.environment.name;
  switch (envName) {
    case "local":
      return {
        INSTRUMENTATION_KEY: "",
        PROSPECTS_API_URL: "https://cs-prospecting-api-test.creditsafe.com/v1",
        DATA_CLEANING_API_URL:
          "https://cs-datacleaning-api-test.creditsafe.com/v1",
        ADOBE_ANALYTICS_SCRIPT_URL:
          "//assets.adobedtm.com/a7310339adb3/a5f8993ceb1c/launch-068166bb883f-development.min.js",
        UPP_API_URL: "https://test-api.uppdev.creditsafe.com/v1",
        UPP_APP_URL: "https://test.uppdev.creditsafe.com",
        UPP_UBO_URL: "https://test-api.uppdev.creditsafe.com/ubo",
        LINKAGES_ROUTER_URL:
          "https://mylogin.test.creditsafe.com/LinkagesPortal",
        PORTFOLIOS_API_URL: "https://localhost:5011/v1",
        DEBUG_MODE: true,
        LOCIZE_ENABLED: false,
        WALKME_SRC: "",
        SPLITIO_KEY: "5l6lq4d3d69ko7u4vd3rvq8ddtfh0fp8c7gg",
      };
    case "test":
      return {
        INSTRUMENTATION_KEY: "dc547a23-9d9a-4389-ab31-2bd70c7833b6",
        PROSPECTS_API_URL: "https://cs-prospecting-api-test.creditsafe.com/v1",
        DATA_CLEANING_API_URL: isAzure
          ? "https://cs-datacleaning-api-test.azurewebsites.net/v1"
          : "https://cs-datacleaning-api-test.creditsafe.com/v1",
        ADOBE_ANALYTICS_SCRIPT_URL:
          "//assets.adobedtm.com/a7310339adb3/a5f8993ceb1c/launch-068166bb883f-development.min.js",
        UPP_API_URL: "https://test-api.uppdev.creditsafe.com/v1",
        UPP_APP_URL: "https://test.uppdev.creditsafe.com",
        UPP_UBO_URL: "https://test-api.uppdev.creditsafe.com/ubo",
        LINKAGES_ROUTER_URL:
          "https://mylogin.test.creditsafe.com/LinkagesPortal",
        PORTFOLIOS_API_URL:
          "https://cs-portfolios-api-test.azurewebsites.net/v1",
        DEBUG_MODE: false,
        LOCIZE_ENABLED: false,
        WALKME_SRC:
          "https://cdn.walkme.com/users/b81e4f55bb224cf8a3e6c24ffc6a9360/test/walkme_b81e4f55bb224cf8a3e6c24ffc6a9360_https.js",
        SPLITIO_KEY: "5l6lq4d3d69ko7u4vd3rvq8ddtfh0fp8c7gg",
      };
    case "stage":
      return {
        INSTRUMENTATION_KEY: "838f725b-2651-4966-b68b-eef30d4e3752",
        PROSPECTS_API_URL: "https://cs-prospecting-api-stage.creditsafe.com/v1",
        DATA_CLEANING_API_URL: isAzure
          ? "https://cs-datacleaning-api-stage.azurewebsites.net/v1"
          : "https://cs-datacleaning-api-stage.creditsafe.com/v1",
        ADOBE_ANALYTICS_SCRIPT_URL:
          "//assets.adobedtm.com/launch-EN99db32ecb96e46f0a40a48ec5ae695f1-staging.min.js",
        UPP_API_URL: "https://demo-api.uppdev.creditsafe.com/v1",
        UPP_APP_URL: "https://stage.uppdev.creditsafe.com",
        UPP_UBO_URL: "https://demo-api.uppdev.creditsafe.com/ubo",
        LINKAGES_ROUTER_URL:
          "https://mylogin-staged.creditsafe.com/LinkagesPortal",
        PORTFOLIOS_API_URL:
          "https://cs-portfolios-api-stage.azurewebsites.net/v1",
        DEBUG_MODE: false,
        LOCIZE_ENABLED: true,
        WALKME_SRC:
          "https://cdn.walkme.com/users/b81e4f55bb224cf8a3e6c24ffc6a9360/test/walkme_b81e4f55bb224cf8a3e6c24ffc6a9360_https.js",
        SPLITIO_KEY: "bqpk6o1qe5kdq6lio6ig7a4ljsn9rlbbfvun",
      };
    case "production":
      return {
        INSTRUMENTATION_KEY: "97f5b3be-8d26-4ced-812c-00d9e33041f8",
        PROSPECTS_API_URL: "https://cs-prospecting-api.creditsafe.com/v1",
        DATA_CLEANING_API_URL: isAzure
          ? "https://cs-datacleaning-api-prod.azurewebsites.net/v1"
          : "https://cs-datacleaning-api.creditsafe.com/v1",
        ADOBE_ANALYTICS_SCRIPT_URL:
          "//assets.adobedtm.com/a7310339adb3/a5f8993ceb1c/launch-9748cef93773.min.js",
        UPP_API_URL: "https://api.app.creditsafe.com/v1",
        UPP_APP_URL: "https://app.creditsafe.com",
        UPP_UBO_URL: "https://api.app.creditsafe.com/ubo",
        LINKAGES_ROUTER_URL: "https://mylogin.creditsafe.com/LinkagesPortal",
        PORTFOLIOS_API_URL:
          "https://cs-portfolios-api-prod.azurewebsites.net/v1",
        DEBUG_MODE: false,
        LOCIZE_ENABLED: true,
        WALKME_SRC:
          "https://cdn.walkme.com/users/b81e4f55bb224cf8a3e6c24ffc6a9360/walkme_b81e4f55bb224cf8a3e6c24ffc6a9360_https.js",
        SPLITIO_KEY: "f7bml0u4ev951vnoji81o8b3q079psp27rrj",
      };
  }
};

const config = initConfig();
const updateConfig = (isAzure = false) => {
  console.log("isAzure: " + isAzure);
  Object.assign(config, initConfig(isAzure));
  return config;
};

export { updateConfig };
export default config;
