import { get, isEmpty, isNil, isUndefined, omit, omitBy, uniq } from "lodash";
import {
  expandHierarchyKey,
  getActivityCodeFullKey,
  getCountryExcludedFields,
  isInvalidBusinessSignalsCriteria,
  isInvalidBusinessClassificationCriteria,
  isObject,
  parseLocationItemKey,
  isInvalidCompanyAuditorCriteria,
  isInvalidDirectorAgeCriteria,
} from "../../appUtils";
import { removeKey, removeKeyAndChildren } from "../../utils/hierarchyKeys";
import {
  mapCriteriaToCategory,
  numberRangeCriteria,
  toFilterCriteriaKey,
} from "../../utils/searchFilters";
import {
  ActivityCode,
  CriteriaActionTypes,
  FETCH_ACTIVITY_TYPE_SUCCESS,
  LOAD_SAVED_SEARCH,
  Location,
} from "../criteria/types";
import { FETCH_ORDER_INFO_SUCCESS, OrderActionTypes } from "../order/types";
import { KeysMatching } from "../types";
import {
  DELETE_SEARCH_CRITERIA,
  RESET_SEARCH,
  SearchActionTypes,
  SearchState,
  SET_INVALID_FIELDS,
  SET_SEARCH,
  SET_SEARCH_INTERNATIONAL_ACTIVITY,
  SET_SEARCH_LOCATIONS,
  UPDATE_SEARCH_CRITERIA_ARRAY,
  UPDATE_SEARCH_CRITERIA_VALUE_ARRAY,
  SET_SEARCH_LOCAL_ACTIVITY,
  NonNullableSearchState,
  SearchCriteriaKeys,
  SearchCategories,
  SavedSearchesResponseKeys,
  APISearchCriteriaResponseBody,
} from "./types";

export const initialState: SearchState = {
  "companyProfile.companyStatus": ["active"],
};

// eslint-disable-next-line complexity
export default function (
  state = initialState,
  action: SearchActionTypes | OrderActionTypes | CriteriaActionTypes
): SearchState {
  switch (action.type) {
    case SET_SEARCH: {
      return omitBy(
        {
          ...state,
          ...action.payload,
        },
        (x) => isNil(x) || x === ""
      );
    }
    case SET_SEARCH_LOCATIONS: {
      /*
        Handles toggling of geographies search criteria, both from autocomplete and hierarchy.
        'Geographic.test.tsx' tries to explain each possible case for this method.

        Rules
        ---------
        1) If a location is selected, its parent country must also become selected. 
          (This provides clarity for the locations you are searching, and prevents the case where
            you exclude a location without specifying a parent. 'country' becomes the default.)
        
        2) Toggles location 'on' or 'off' depending on if the location is currently selected

        3) If a country is removed, all child locations and exclusions must be removed to prevent violation of rule 1.

        4) If a location is excluded, remove all children of that location, both inclusion/exclusion, neither are valid.

        5) Removing an Exclusion does not cause it to be included
      */
      const { key, isExclude } = action.payload;
      const { country, parentId } = parseLocationItemKey(key);

      let selectedLocations = state["geographic.locations"] || [];
      let excludedLocations = state["geographic.excludeLocations"] || [];

      if (isInvalidBusinessSignalsCriteria(state)) {
        state = omitBy(state, (_, k: string) => k.includes("businessSignals"));
      }

      const isCountry = key === country;
      const isAlreadyIncluded = selectedLocations.includes(key);
      const isAlreadyExcluded = excludedLocations.includes(key);
      const isCountryAlreadyIncluded = selectedLocations.includes(country);
      const isParentAlreadyIncluded = selectedLocations.includes(parentId);

      excludedLocations = removeKey(excludedLocations, key);

      if (isAlreadyIncluded) {
        selectedLocations = removeKey(selectedLocations, key);
      } else {
        selectedLocations = [...selectedLocations, key];
        if (!isCountryAlreadyIncluded && !isCountry) {
          selectedLocations.push(country);
        }
      }

      if (isExclude) {
        selectedLocations = removeKeyAndChildren(selectedLocations, key);

        if (isAlreadyExcluded) {
          excludedLocations = removeKeyAndChildren(excludedLocations, key);
        } else {
          excludedLocations = [
            ...removeKeyAndChildren(excludedLocations, key),
            key,
          ];
        }
      }

      if (isCountry && !selectedLocations.includes(country)) {
        selectedLocations = removeKeyAndChildren(selectedLocations, country);
        excludedLocations = removeKeyAndChildren(excludedLocations, country);
      }

      const newState = {
        ...state,
        "geographic.locations": selectedLocations,
        "geographic.excludeLocations": excludedLocations,
      };

      return !isCountry &&
        selectedLocations.length > 0 &&
        (isParentAlreadyIncluded || isCountryAlreadyIncluded)
        ? newState
        : omit(newState, getExcludeFields(newState));
    }
    case SET_SEARCH_INTERNATIONAL_ACTIVITY: {
      const activityCodesList = getNewActivityCodesStateField(
        state,
        "companyProfile.internationalActivities",
        "companyProfile.excludeInternationalActivities",
        action.payload.key,
        action.payload.isExclude
      );

      const activityCodesSelected = activityCodesList.selectedActivityCodesList;
      const excludedActivityCodes = activityCodesList.excludedActivityCodesList;

      return {
        ...state,
        "companyProfile.internationalActivities": activityCodesSelected,
        "companyProfile.excludeInternationalActivities": excludedActivityCodes,
      };
    }
    case SET_SEARCH_LOCAL_ACTIVITY: {
      const activityCodesList = getNewActivityCodesStateField(
        state,
        "companyProfile.localActivities",
        "companyProfile.excludeLocalActivities",
        action.payload.key,
        action.payload.isExclude
      );

      const activityCodesSelected = activityCodesList.selectedActivityCodesList;
      const excludedActivityCodes = activityCodesList.excludedActivityCodesList;

      return {
        ...state,
        "companyProfile.localActivities": activityCodesSelected,
        "companyProfile.excludeLocalActivities": excludedActivityCodes,
      };
    }
    case UPDATE_SEARCH_CRITERIA_ARRAY: {
      let newState = omitBy(
        {
          ...state,
          [action.payload.key]: action.payload.value,
        },
        (x) => Array.isArray(x) && isEmpty(x)
      );
      if (isInvalidBusinessSignalsCriteria(newState)) {
        newState = omitBy(newState, (_, k: string) =>
          k.includes("businessSignals")
        );
      }
      if (isInvalidDirectorAgeCriteria(newState)) {
        newState = omitBy(
          newState,
          (_, k: string) =>
            k.includes("directorAgeMin") || k.includes("directorAgeMax")
        );
      }
      if (isInvalidBusinessClassificationCriteria(newState)) {
        newState = omitBy(newState, (_, k: string) =>
          k.includes("businessClassifications")
        );
      }
      if (isInvalidCompanyAuditorCriteria(newState)) {
        newState = omitBy(newState, (_, k: string) =>
          k.includes("companyAuditors")
        );
      }
      return newState;
    }
    case UPDATE_SEARCH_CRITERIA_VALUE_ARRAY: {
      const response = updateStateArrayWithValue(
        state,
        action.payload.field,
        action.payload.key
      );
      return response.state;
    }
    case DELETE_SEARCH_CRITERIA: {
      return omit(state, [action.payload.key]);
    }
    case RESET_SEARCH: {
      return initialState;
    }
    case SET_INVALID_FIELDS: {
      return omitBy(
        {
          ...state,
          invalidFields: action.payload,
        },
        (x) => isObject(x) && isEmpty(x)
      );
    }
    case FETCH_ORDER_INFO_SUCCESS:
    case LOAD_SAVED_SEARCH: {
      return mapOrderSearchCriteriaToState(
        get(action.payload, "searchCriteria", {})
      );
    }
    case FETCH_ACTIVITY_TYPE_SUCCESS: {
      return {
        ...state,
        "companyProfile.activityType": action.payload.type,
      };
    }
    default:
      return state;
  }
}

const updateStateArrayWithValue = (
  state: SearchState,
  fieldName: KeysMatching<NonNullableSearchState, string[]>,
  value: string
) => {
  const currentArray = state[fieldName] || ([] as string[]);
  const isAdding = !currentArray.includes(value);

  const newArray = isAdding
    ? [...currentArray, value]
    : currentArray.filter((v: string) => v !== value);

  let updatedState = omitBy(
    {
      ...state,
      [fieldName]: newArray,
    },
    (x) => Array.isArray(x) && isEmpty(x)
  );

  if (isUndefined(updatedState[fieldName]) && initialState[fieldName]) {
    updatedState[fieldName] = initialState[fieldName] as string[];
  }

  if (isInvalidBusinessSignalsCriteria(updatedState)) {
    updatedState = omitBy(updatedState, (_, k: string) =>
      k.includes("businessSignals")
    );
  }

  return { state: updatedState, isAdding };
};

const mapOrderSearchCriteriaToState = (
  searchCriteria: APISearchCriteriaResponseBody
) => {
  const searchState: SearchState = {};

  searchState.isLoadedCriteria = true;

  searchState["geographic.locations"] =
    mergeCountriesAndLocationsCriteria(searchCriteria);

  const excludeLocations: Location[] = get(
    searchCriteria,
    "excludeLocations",
    []
  );
  searchState["geographic.excludeLocations"] = excludeLocations.map(
    ({ key }) => key
  );

  Object.entries(searchCriteria).forEach(([key, value]) => {
    const mappedKey = mapCriteriaToCategory[key as SavedSearchesResponseKeys];
    if (mappedKey) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      searchState[mappedKey] = value as any;
    }
  });

  if (searchState["businessSignals.creditScoreMax"] === -1) {
    searchState["businessSignals.creditScoreIncludeNegativeScores"] = true;
    delete searchState["businessSignals.creditScoreMax"];
  }

  const internationalActivities = searchCriteria.internationalActivities || [];

  const excludeInternationalActivities: ActivityCode[] = get(
    searchCriteria,
    "excludeInternationalActivities",
    []
  );

  const localActivities = searchCriteria.localActivities || [];

  const excludeLocalActivities: ActivityCode[] = get(
    searchCriteria,
    "excludeLocalActivities",
    []
  );

  if (internationalActivities.length > 0) {
    searchState["companyProfile.internationalActivities"] =
      internationalActivities.map((x) => getActivityCodeFullKey(x));
  }

  if (excludeInternationalActivities.length > 0) {
    searchState["companyProfile.excludeInternationalActivities"] =
      excludeInternationalActivities.map((x) => getActivityCodeFullKey(x));
  }

  if (localActivities.length > 0) {
    searchState["companyProfile.localActivities"] = localActivities.map((x) =>
      getActivityCodeFullKey(x)
    );
  }

  if (excludeLocalActivities.length > 0) {
    searchState["companyProfile.excludeLocalActivities"] =
      excludeLocalActivities.map((x) => getActivityCodeFullKey(x));
  }

  return searchState;
};

const getExcludeFields = (state: SearchState) => {
  const countriesArray = state["geographic.locations"] || [];
  let countryExcludedField: SearchCriteriaKeys[] = [];

  countriesArray.forEach((country: string) => {
    const countryRules = getCountryExcludedFields(country);
    Object.entries(countryRules).forEach(([key, values]) => {
      (values || []).forEach((x) => {
        countryExcludedField.push(
          toFilterCriteriaKey(key as SearchCategories, x)
        );
      });
    });
  });

  countryExcludedField = uniq(countryExcludedField);

  const excludeFields: SearchCriteriaKeys[] = [];

  countryExcludedField.forEach((field) => {
    if ((numberRangeCriteria as ReadonlyArray<string>).includes(field)) {
      excludeFields.push(`${field}Min` as keyof SearchState);
      excludeFields.push(`${field}Max` as keyof SearchState);
    } else {
      excludeFields.push(field);
    }
  });

  if (countriesArray.length > 1) {
    excludeFields.push("exclusions.uniqueTelephoneNumber");
  }
  if (countriesArray.length > 1 || !countriesArray.includes("GB")) {
    excludeFields.push("exclusions.excludeHasCCJ");
  }

  excludeFields.push("companyProfile.internationalActivities");
  excludeFields.push("companyProfile.excludeInternationalActivities");
  excludeFields.push("companyProfile.localActivities");
  excludeFields.push("companyProfile.excludeLocalActivities");
  excludeFields.push("companyProfile.activityType");
  excludeFields.push("companyProfile.legalForms");
  excludeFields.push("companyProfile.businessClassifications");
  excludeFields.push("companyProfile.directorAgeMin");
  excludeFields.push("companyProfile.directorAgeMax");
  excludeFields.push("companyProfile.addressTypes");
  excludeFields.push("companyProfile.companyAuditors");
  excludeFields.push("companyProfile.caExports");

  return excludeFields;
};

const getNewActivityCodesStateField = (
  state: SearchState,
  stateField: string,
  excludedStateField: string,
  activityKey: string,
  isExclude?: boolean
) => {
  const splittedKey = activityKey.split("/");
  const parentId = splittedKey.slice(0, splittedKey.length - 1).join("/");
  const parents = expandHierarchyKey(parentId);
  let selectedActivityCodes = get(state, stateField, []);
  let excludedActivityCodes = get(state, excludedStateField, []);
  let isParentExcluded = false;
  let parent = activityKey;
  if (excludedActivityCodes?.length > 0)
    splittedKey.forEach(() => {
      if (isParentExcluded) return;
      else {
        parent = parent.substring(0, parent.lastIndexOf("/"));
        isParentExcluded = excludedActivityCodes.includes(parent);
      }
    });
  const hasActivityCode = selectedActivityCodes.includes(activityKey);
  const hasActivityCodeExcluded = excludedActivityCodes.includes(activityKey);

  selectedActivityCodes = hasActivityCode
    ? selectedActivityCodes.filter((v: string) => v !== activityKey)
    : !isParentExcluded
    ? [...selectedActivityCodes, activityKey]
    : selectedActivityCodes;

  if (selectedActivityCodes.includes(activityKey) && hasActivityCodeExcluded) {
    excludedActivityCodes = excludedActivityCodes.filter(
      (v: string) => v !== activityKey
    );
  }

  if (isExclude) {
    selectedActivityCodes = selectedActivityCodes.filter(
      (v: string) => v !== activityKey && !v.startsWith(activityKey)
    );

    excludedActivityCodes = hasActivityCodeExcluded
      ? excludedActivityCodes.filter((v: string) => v !== activityKey)
      : [
          ...excludedActivityCodes.filter((v: string) => {
            return !v.startsWith(activityKey) && !parents.includes(v);
          }),
          activityKey,
        ];
  }

  return {
    selectedActivityCodesList: selectedActivityCodes,
    excludedActivityCodesList: excludedActivityCodes,
  };
};

const mergeCountriesAndLocationsCriteria = (
  searchCriteria: APISearchCriteriaResponseBody
) => {
  const countries = searchCriteria.countries || [];
  const locations = searchCriteria.locations || [];

  return countries.concat(locations.map(({ key }) => key));
};
