import { get } from "lodash";
import { EnvironmentNames } from "./";

const envName = window.environment.name;

const blockedFeatures: {
  [Key in EnvironmentNames]: Record<string, unknown>;
} = {
  local: {},
  test: {},
  stage: {},
  production: {},
};

export enum FEATURE_FLAGS {
  countries = "countries",
}

export function isFeatureAvailable(
  feature: FEATURE_FLAGS,
  value: string
): boolean {
  const envRules = get(blockedFeatures, [envName], {});
  const featureRules: string[] = get(envRules, [feature], []);
  return featureRules.find((x) => x === value) === undefined;
}
