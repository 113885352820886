import set from "lodash/set";

interface AdobeData {
  detail: {
    action?: string;
    linkName?: string;
    mbox?: string;
  };
}

interface AdobeCustomEvent {
  detail: {
    action: string;
    linkName: string;
  };
}

export const fireViewStartCustomEvent = (data?: AdobeData): void => {
  const event = new CustomEvent("event-view-start", data);
  document.dispatchEvent(event);
};

export const fireViewEndCustomEvent = (data?: AdobeData): void => {
  const event = new CustomEvent("event-view-end", data);
  const el = document.querySelector("body") || document;
  el.dispatchEvent(event);
};

export const fireActionTriggerCustomEvent = (
  target: EventTarget | null,
  data: AdobeCustomEvent
) => {
  const event = new CustomEvent("event-action-trigger", data);
  setDigitalData("currentAction", data.detail.action);
  setDigitalData("currentActionTarget", data.detail.linkName);

  const el = target || document.querySelector("body") || document;

  el.dispatchEvent(event);
};

/* WARNING - Never provide a "value" that could be set by a user. */
export const setDigitalData = (path: string, value: string): void => {
  set(window.digitalData, path, value);
};
