import { omitBy, uniq } from "lodash";
import { getActivityCodeFromFullKey } from "../appUtils";
import {
  APISearchCriteriaBody,
  FlattenedSearchState,
} from "../store/search/types";
import { ActivityCodeSearchBody, Location } from "../store/criteria/types";
import {
  hasParentIncluded,
  hasActivityCodeParentIncluded,
  isUnnecessaryExclusion,
  isUnnecessaryExclusionForActivityCode,
} from "./hierarchyKeys";
import { GEOGRAPHIC_SEPERATOR } from "../constants";

export const countriesMapper = (locations: string[]): string[] =>
  uniq(locations.map((x: string) => x.split(GEOGRAPHIC_SEPERATOR)[0]));

const locationsMapper = (locations: string[]): Location[] => {
  const countries = uniq(
    locations.map((x: string) => x.split(GEOGRAPHIC_SEPERATOR)[0])
  );
  const result = locations
    .filter((x: string) => !countries.includes(x))
    .filter((x: string, _: number, a: string[]) => !hasParentIncluded(x, a))
    .map((x: string) => ({
      key: x,
    }));

  return result;
};

const excludeLocationsMapper = (
  excludeLocations: string[],
  locations: string[]
): Location[] =>
  excludeLocations
    .filter((key: string) => !isUnnecessaryExclusion(key, locations))
    .map((key: string) => ({ key }));

const excludeActivityCodesMapper = (
  excludeActivityCodes: string[],
  activityCodes: string[]
): ActivityCodeSearchBody[] =>
  excludeActivityCodes
    .filter(
      (key: string) =>
        !isUnnecessaryExclusionForActivityCode(key, activityCodes)
    )
    .map((key: string) => ({ code: getActivityCodeFromFullKey(key) }));

const activitiesMapper = (activities: string[]): ActivityCodeSearchBody[] =>
  activities
    .filter((key: string) => !hasActivityCodeParentIncluded(key, activities))
    .map((key: string) => ({
      code: getActivityCodeFromFullKey(key),
    }));

export const mapSearchCriteriaToApiPayload = (
  searchCriteria: FlattenedSearchState
): APISearchCriteriaBody => {
  if (searchCriteria.creditScoreIncludeNegativeScores) {
    searchCriteria.creditScoreMax = -1;
    delete searchCriteria.creditScoreMin;
    delete searchCriteria.creditScoreIncludeNegativeScores;
  }
  searchCriteria = omitBy(searchCriteria, (x) => x === null);

  const result: APISearchCriteriaBody = {
    ...searchCriteria,
    excludeLocations: excludeLocationsMapper(
      searchCriteria.excludeLocations || [],
      searchCriteria.locations || []
    ),
    locations: locationsMapper(searchCriteria.locations || []),
    internationalActivities: activitiesMapper(
      searchCriteria.internationalActivities || []
    ),
    excludeInternationalActivities: excludeActivityCodesMapper(
      searchCriteria.excludeInternationalActivities || [],
      searchCriteria.internationalActivities || []
    ),
    localActivities: activitiesMapper(searchCriteria.localActivities || []),
    excludeLocalActivities: excludeActivityCodesMapper(
      searchCriteria.excludeLocalActivities || [],
      searchCriteria.localActivities || []
    ),
    countries: countriesMapper(searchCriteria.locations || []),
  };

  return omitBy(result, (x) => Array.isArray(x) && x.length === 0);
};
