import { action } from "typesafe-actions";
import {
  FETCH_SAFENUMBERS,
  FETCH_SAFENUMBERS_ERROR,
  FETCH_SAFENUMBERS_SUCCESS,
  UPDATE_SAFENUMBERS_SUCCESS,
  UPDATE_SAFENUMBERS_ERROR,
  UPDATE_SAFENUMBERS,
  RESET_STATUS,
} from "./types";

export const fetchSafeNumbers = () => action(FETCH_SAFENUMBERS);

export const fetchSafeNumbersSuccess = (payload: string[]) => {
  return action(FETCH_SAFENUMBERS_SUCCESS, payload);
};
export const fetchSafeNumbersError = () => action(FETCH_SAFENUMBERS_ERROR);

export const updateSafeNumbers = (payload: string[]) => {
  return action(UPDATE_SAFENUMBERS, payload);
};
export const updateSafeNumbersSuccess = (payload: boolean) => {
  return action(UPDATE_SAFENUMBERS_SUCCESS, payload);
};
export const updateSafeNumbersError = () => action(UPDATE_SAFENUMBERS_ERROR);
export const resetStatus = () => action(RESET_STATUS);
