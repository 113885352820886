import { combineEpics, Epic as ReduxObservableEpic } from "redux-observable";
import * as userEpics from "./user/epics";
import * as orderEpics from "./order/epics";
import * as jobsEpics from "./jobs/epics";
import * as portfoliosEpics from "./portfolios/epics";
import * as criteriaEpics from "./criteria/epics";
import * as appEpics from "./app/epics";
import * as adminEpics from "./admin/epics";
import { RootAction } from "./types";
import { RootState } from "./rootReducer";
import { EpicDependencies } from "./epicDependencies";

const epics = combineEpics(
  ...Object.values(userEpics),
  ...Object.values(orderEpics),
  ...Object.values(jobsEpics),
  ...Object.values(portfoliosEpics),
  ...Object.values(criteriaEpics),
  ...Object.values(appEpics),
  ...Object.values(adminEpics)
);

export type Epic<T extends RootAction> = ReduxObservableEpic<
  RootAction,
  T,
  RootState,
  EpicDependencies
>;

export default epics;
