import * as OrderActions from "./actions";
import { ActionType } from "typesafe-actions";
import {
  APISearchCriteriaResponseBody,
  FlattenedSearchState,
} from "../search/types";

export const FETCH_COMPANIES_COUNT = "FETCH_COMPANIES_COUNT";
export const FETCH_COMPANIES_COUNT_SUCCESS = "FETCH_COMPANIES_COUNT_SUCCESS";
export const FETCH_COMPANIES_COUNT_ERROR = "FETCH_COMPANIES_COUNT_ERROR";
export const RESET_COMPANIES_COUNT = "RESET_COMPANIES_COUNT";
export const FETCH_ORDERS = "FETCH_ORDERS";
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
export const FETCH_ORDERS_ERROR = "FETCH_ORDERS_ERROR";
export const CLEAR_ORDERS = "CLEAR_ORDERS";
export const FETCH_AMOUNT_PER_COUNTRY = "FETCH_AMOUNT_PER_COUNTRY";
export const FETCH_AMOUNT_PER_COUNTRY_SUCCESS =
  "FETCH_AMOUNT_PER_COUNTRY_SUCCESS";
export const FETCH_AMOUNT_PER_COUNTRY_ERROR = "FETCH_AMOUNT_PER_COUNTRY_ERROR";
export const FETCH_NONLTD_CREDITS_PER_COUNTRY =
  "FETCH_NONLTD_CREDITS_PER_COUNTRY";
export const FETCH_NONLTD_CREDITS_PER_COUNTRY_SUCCESS =
  "FETCH_NONLTD_CREDITS_PER_COUNTRY_SUCCESS";
export const FETCH_NONLTD_CREDITS_PER_COUNTRY_ERROR =
  "FETCH_NONLTD_CREDITS_PER_COUNTRY_ERROR";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_ORDER_ERROR = "CREATE_ORDER_ERROR";
export const FETCH_ORDER_INFO = "FETCH_ORDER_INFO";
export const FETCH_ORDER_INFO_SUCCESS = "FETCH_ORDER_INFO_SUCCESS";
export const FETCH_ORDER_INFO_ERROR = "FETCH_ORDER_INFO_ERROR";
export const FETCH_ORDER_PROSPECTS = "FETCH_ORDER_PROSPECTS";
export const FETCH_ORDER_PROSPECTS_SUCCESS = "FETCH_ORDER_PROSPECTS_SUCCESS";
export const FETCH_ORDER_PROSPECTS_ERROR = "FETCH_ORDER_PROSPECTS_ERROR";
export const FETCH_ORDER_PROSPECT_INFO = "FETCH_ORDER_PROSPECT_INFO";
export const FETCH_ORDER_PROSPECT_INFO_SUCCESS =
  "FETCH_ORDER_PROSPECT_INFO_SUCCESS";
export const FETCH_ORDER_PROSPECT_INFO_ERROR =
  "FETCH_ORDER_PROSPECT_INFO_ERROR";
export const DOWNLOAD_PROSPECT_LIST = "DOWLOAD_PROSPECT_LIST";
export const DOWNLOAD_PROSPECT_LIST_SUCCESS = "DOWNLOAD_PROSPECT_LIST_SUCCESS";
export const DOWNLOAD_PROSPECT_LIST_ERROR = "DOWNLOAD_PROSPECT_LIST_ERROR";
export const RESET_PROSPECT_LIST_FILE_PATH = "RESET_PROSPECT_LIST_FILE_PATH";
export const FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT =
  "FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT";
export const FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT_SUCCESS =
  "FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT_SUCCESS";
export const FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT_ERROR =
  "FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT_ERROR";

export type OrderStatus = "pending" | "completed" | "failed";
export type CompanyStatus = "active" | "other";
export type PostCodeSearchTypes = "startsWith" | "exactMatch";
export type CompanyTypes = "Ltd" | "NonLtd";
export type CompanyAddressTypes = "headOffice" | "branch" | "legalPerson";
export type RegisteredTypes = "limited" | "nonLimited";
export type CreateOrderValidationErrors =
  | "name"
  | "description"
  | "quantity"
  | "credits"
  | null;
export type CreateOrderErrors =
  | "OrderNameNotUnique"
  | "NoCreditsAvailable"
  | "UnknownError";

export type OrderResponse = {
  id: string;
  name: string;
  status: OrderStatus;
  description: string;
  userId: number;
  customerId: number;
  totalAmount: number;
  createdAt: Date;
  exportedAt?: Date;
  expiresAt?: Date;
  isLegacy: boolean;
};

export type OrderInfoResponse = {
  id: string;
  name: string;
  description: string;
  status: OrderStatus;
  userId: number;
  customerId: number;
  createdAt: Date;
  modifiedAt?: Date;
  orderAmounts: { [key: string]: number };
  searchCriteria: APISearchCriteriaResponseBody;
};

export type OrderProspect = {
  csCompanyId: string;
  coNumber?: string;
  orgNumber?: string;
  name?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  address5?: string;
  country?: string;
  companyType?: RegisteredTypes;
};

export type Director = {
  id: string;
  name: string;
  dateOfBirth: string;
  startDate: Date;
  role: string;
};

export type OrderProspectInfo = {
  csCompanyId: string;
  coNumber?: string;
  name?: string;
  country?: string;
  orgNumber?: string;
  status?: CompanyStatus;
  companyType?: RegisteredTypes;
  legalForm?: string;
  incorpDate?: Date;
  employees?: string;
  activityCode?: string;
  activityDescription?: string;
  creditScore?: number;
  commonScore?: string;
  creditLimit?: number;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  address5?: string;
  region?: string;
  zip?: string;
  phone?: string;
  marketingAllowed?: boolean;
  website?: string;
  directors?: Director[];
  creditScoreDefinition?: string;
};

export type DownloadProspectPayload = string | null;

export interface OrderState {
  companiesCount: number | null;
  isLoadingCompaniesCount: boolean;
  hasCompaniesCountError: boolean;
  orders: OrderResponse[];
  totalOrders: number;
  createOrderIsLoading: boolean;
  createOrderError: null | CreateOrderErrors;
  createdOrderSuccess: boolean;
  amountPerCountry: {
    [char: string]: {
      Limited: number;
      NonLimited: number;
      Total: number;
    };
  };
  isLoadingAmountPerCountry: boolean;
  orderInfo: null | OrderInfoResponse;
  hasOrderInfoError: boolean;
  orderProspects: OrderProspect[];
  totalOrderProspects: number;
  orderProspectsContinuationToken: string[];
  isLoadingProspects: boolean;
  isLoadingOrderProspects: boolean;
  orderProspectInfo: null | OrderProspectInfo;
  hasOrderProspectInfoError: boolean;
  isLoadingOrderProspectInfo: boolean;
  downloadedOrderId: string | null;
  isLoadingProspectsExport: boolean;
  hasProspectsExportError: boolean;
  prospectListFilePath: DownloadProspectPayload;
  isLoadingExcludedCompaniesPreviousOrdersCount: boolean;
  excludedCompaniesPreviousOrdersCount: number;
  usedCreditsForNonLtd: {
    [char: string]: number;
  };
  isLoadingUsedCreditsForNonLtd: boolean;
}

export type PreviewOrderPayload = FlattenedSearchState;

export type PreviewOrderResponse = {
  total: number;
  amountPerCountry: {
    [char: string]: {
      Limited: number;
      NonLimited: number;
      Total: number;
    };
  };
};

export type FetchNonLtdUsedCreditsResponse = { [char: string]: number };

export type FetchExcludedCompaniesCountPayload = {
  excludePreviousOrdersDuration: string;
};

export type FetchExcludedCompaniesCountResponse = {
  count: number;
};

export type FetchOrdersPayload = {
  page: number;
  limit: number;
};

export type CreateOrderPayload = {
  name: string;
  description?: string;
  requestedAmounts: {
    [country: string]: {
      Limited: number | undefined;
      NonLimited: number | undefined;
      Total: number | undefined;
    };
  };
  searchCriteria: FlattenedSearchState;
  previewCount: number;
};

export type OrdersResponse = {
  orders: OrderResponse[];
  totalOrders: number;
};

export type FetchOrderByIdPayload = {
  orderId: string;
  fileFormat?: string;
};

export type FetchOrderProspectsPayload = {
  orderId: string;
  page: number;
  limit: number;
};

export type FetchOrderProspectPayload = {
  orderId: string;
  csCompanyId: string;
};

export type OrderProspectsResponse = {
  prospects: OrderProspect[];
  totalProspects: number;
  continuationToken: string;
};

export type OrderActionTypes = ActionType<typeof OrderActions>;
