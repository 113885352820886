import { Epic } from "../rootEpic";
import { ActionType, isOfType } from "typesafe-actions";
import { FETCH_SAFENUMBERS, UPDATE_SAFENUMBERS } from "./types";
import { catchError, exhaustMap, filter, map, switchMap } from "rxjs/operators";
import {
  fetchSafeNumbersError,
  fetchSafeNumbersSuccess,
  updateSafeNumbersError,
  updateSafeNumbersSuccess,
} from "./actions";
import { of } from "rxjs";

export const fetchSafeNumberEpic: Epic<
  | ActionType<typeof fetchSafeNumbersSuccess>
  | ActionType<typeof fetchSafeNumbersError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_SAFENUMBERS)),
    switchMap(() =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/admin/suppressions`,
      }).pipe(
        switchMap(({ response }) => of(fetchSafeNumbersSuccess(response))),
        catchError(() => of(fetchSafeNumbersError()))
      )
    )
  );
export const updateSuppressionListEpic: Epic<
  | ActionType<typeof updateSafeNumbersSuccess>
  | ActionType<typeof updateSafeNumbersError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(UPDATE_SAFENUMBERS)),
    exhaustMap(({ payload }) =>
      request({
        method: "POST",
        url: `${config.PROSPECTS_API_URL}/admin/suppressions`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }).pipe(
        map(({ response }) => updateSafeNumbersSuccess(response)),
        catchError(() => of(updateSafeNumbersError()))
      )
    )
  );
