import { createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";

const defaultFonts = [
  "Open Sans",
  "Arial",
  "Helvetica Neue",
  "Helvetica",
  "sans-serif",
].join(",");

const headingFonts = [
  "Varela Round",
  "Arial",
  "Helvetica Neue",
  "Helvetica",
  "sans-serif",
].join(",");

const drawerWidth = 225;

const inputFontSize = "1.2rem";
const secondaryColor = "#329be2";

const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    background: {
      default: "white",
    },
    primary: {
      main: "#ee2e24",
    },
    secondary: {
      main: secondaryColor,
    },
    success: {
      main: green[600],
      contrastText: "#ffffff",
    },
    error: {
      main: red[600],
      light: red[50],
    },
  },

  typography: {
    /* 
      `htmlFontSize` is the font size for the <html> element, browser default is 16px, (62.5% of 16px = 10px)  
    */
    htmlFontSize: 10,
    fontFamily: defaultFonts,
    h1: {
      fontFamily: headingFonts,
      fontSize: "35px",
    },
    h2: {
      fontFamily: headingFonts,
      fontSize: "24px",
    },
    h3: {
      fontFamily: headingFonts,
      fontSize: "20px",
    },
    h4: {
      fontFamily: headingFonts,
    },
    h5: {
      fontFamily: headingFonts,
    },
    h6: {
      fontFamily: headingFonts,
    },
    button: {
      textTransform: "none",
    },
  },

  props: {
    MuiToolbar: {
      variant: "dense",
    },
    MuiTextField: {
      size: "small",
      color: "secondary",
    },
    MuiDialog: {
      disableEscapeKeyDown: true,
      disableBackdropClick: true,
    },
  },

  overrides: {
    MuiAppBar: {
      colorDefault: { backgroundColor: "#fafafa" },
    },
    MuiCard: {
      root: {
        borderTop: "#ee2e24 2px solid",
        borderRadius: "0 0 2px 2px",
        padding: "16px",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        "border-color": "lightGrey",
      },
    },
    MuiTextField: {
      root: {
        borderRadius: 4,
        backgroundColor: "white",

        "&.MuiOutlinedInput-root": {
          "&:hover fieldset": {
            borderColor: "#bdbdbd",
          },
          "&.Mui-focused fieldset": {
            borderColor: secondaryColor,
          },
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: inputFontSize,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: inputFontSize,
      },
    },
    MuiTab: {
      root: {
        backgroundColor: "#f5f5f5",
        "&$selected": {
          backgroundColor: "#eeeeee",
        },
      },
    },
    MuiDrawer: {
      root: {
        width: drawerWidth,
      },
      paper: {
        width: drawerWidth,
        backgroundColor: "#e1e1e1",
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 0,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: defaultTheme.spacing(1),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: defaultTheme.palette.grey[200],
      },
    },
    MuiDialogContent: {
      root: {
        padding: defaultTheme.spacing(2, 1),
      },
    },
  },
});

export default responsiveFontSizes(theme);
