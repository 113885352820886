import * as AdminActions from "./actions";
import { ActionType } from "typesafe-actions";
export const SET_REQUIRES_ADMIN_UPDATE = "SET_REQUIRES_ADMIN_UPDATE";

export enum Status {
  loading = "loading",

  loaded = "loaded",

  loadingError = "loadingError",

  saving = "saving",

  saved = "saved",

  savingError = "savingError",

  unset = "unset",
}

export interface AdminState {
  safeNumbers: { data: string[]; status: Status; errMsg: string };
  saveStatus: Status;
}

export const FETCH_SAFENUMBERS = "FETCH_SAFENUMBERS";
export const FETCH_SAFENUMBERS_SUCCESS = "FETCH_SAFENUMBERS_SUCCESS";
export const FETCH_SAFENUMBERS_ERROR = "FETCH_SAFENUMBERS_ERROR";
export const UPDATE_SAFENUMBERS = "UPDATE_SAFENUMBERS";
export const UPDATE_SAFENUMBERS_SUCCESS = "UPDATE_SAFENUMBERS_SUCCESS";
export const UPDATE_SAFENUMBERS_ERROR = "UPDATE_SAFENUMBERS_ERROR";
export const RESET_STATUS = "RESET_STATUS";
export type AdminActionTypes = ActionType<typeof AdminActions>;
