import React from "react";
import getAppRoutes from "../../components/AppRoutes";
import { Switch, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { getIsAdminUserAllowed } from "../../store/user/selectors";

const Routes = () => {
  const isAdmin = useSelector(getIsAdminUserAllowed);
  const appRoutes = isAdmin
    ? getAppRoutes()
    : getAppRoutes().filter((x) => x.key !== "admin-route");
  return (
    <div>
      <Switch>
        {appRoutes.map((Route, i) => {
          return React.cloneElement(Route, { key: `@appRoutes/${i}` });
        })}
      </Switch>
    </div>
  );
};

export default withRouter(Routes);
