import {
  CLEAR_MAPPING_JOB,
  CREATE_JOB,
  CREATE_JOB_ERROR,
  CREATE_JOB_SUCCESS,
  FETCH_JOBS,
  FETCH_JOBS_ERROR,
  FETCH_JOBS_SUCCESS,
  FETCH_JOB_DETAILS,
  FETCH_JOB_DETAILS_ERROR,
  FETCH_JOB_DETAILS_SUCCESS,
  FETCH_JOB_SAMPLE,
  FETCH_JOB_SAMPLE_ERROR,
  FETCH_JOB_SAMPLE_SUCCESS,
  JobActionTypes,
  JobsState,
  SAVE_JOB_MAPPINGS,
  SAVE_JOB_MAPPINGS_ERROR,
  SET_MAPPING_JOB,
  SUBMIT_JOB_ERROR,
  SUBMIT_JOB_SUCCESS,
} from "./types";

export const initialState: JobsState = {
  jobs: [],
  totalJobs: 0,
  isLoadingJobs: false,
  isCreatingJob: false,
  createJobSuccess: false,
  createJobError: null,
  isSubmittingJob: false,
  submitJobSuccess: false,
  submitJobError: false,
  mappingDetails: {
    job: null,
    sample: null,
    fileDetails: null,
    isLoading: false,
    hasError: false,
  },
};

export default function (
  state = initialState,
  action: JobActionTypes
): JobsState {
  switch (action.type) {
    case FETCH_JOBS: {
      return {
        ...state,
        isLoadingJobs: true,
        createJobSuccess: false,
        createJobError: null,
      };
    }
    case FETCH_JOBS_SUCCESS: {
      return {
        ...state,
        jobs: action.payload.jobs,
        totalJobs: action.payload.total,
        isLoadingJobs: false,
      };
    }
    case FETCH_JOBS_ERROR: {
      return {
        ...state,
        isLoadingJobs: false,
      };
    }
    case CREATE_JOB: {
      return {
        ...state,
        isCreatingJob: true,
        createJobSuccess: false,
        createJobError: null,
      };
    }
    case CREATE_JOB_SUCCESS: {
      return {
        ...state,
        isCreatingJob: false,
        createJobSuccess: true,
        jobs: [],
        totalJobs: 0,
      };
    }
    case CREATE_JOB_ERROR: {
      return {
        ...state,
        isCreatingJob: false,
        createJobError: action.payload,
      };
    }
    case SET_MAPPING_JOB: {
      return {
        ...state,
        mappingDetails: {
          ...state.mappingDetails,
          job: action.payload,
        },
      };
    }
    case CLEAR_MAPPING_JOB: {
      return {
        ...state,
        isSubmittingJob: false,
        submitJobSuccess: false,
        submitJobError: false,
        mappingDetails: {
          job: null,
          sample: null,
          fileDetails: null,
          isLoading: false,
          hasError: false,
        },
      };
    }
    case FETCH_JOB_DETAILS: {
      return {
        ...state,
        mappingDetails: {
          ...state.mappingDetails,
          isLoading: true,
          hasError: false,
        },
      };
    }
    case FETCH_JOB_DETAILS_SUCCESS: {
      return {
        ...state,
        mappingDetails: {
          ...state.mappingDetails,
          job: action.payload,
        },
      };
    }
    case FETCH_JOB_DETAILS_ERROR: {
      return {
        ...state,
        mappingDetails: {
          ...state.mappingDetails,
          isLoading: false,
          hasError: true,
        },
      };
    }
    case FETCH_JOB_SAMPLE: {
      return {
        ...state,
        mappingDetails: {
          ...state.mappingDetails,
          hasError: false,
        },
      };
    }
    case FETCH_JOB_SAMPLE_SUCCESS: {
      return {
        ...state,
        mappingDetails: {
          ...state.mappingDetails,
          sample: action.payload.sample,
          fileDetails: action.payload.fileDetails,
          isLoading: false,
        },
      };
    }
    case FETCH_JOB_SAMPLE_ERROR: {
      return {
        ...state,
        mappingDetails: {
          ...state.mappingDetails,
          isLoading: false,
          hasError: true,
        },
      };
    }
    case SAVE_JOB_MAPPINGS: {
      return {
        ...state,
        isSubmittingJob: true,
        submitJobError: false,
      };
    }
    case SAVE_JOB_MAPPINGS_ERROR: {
      return {
        ...state,
        isSubmittingJob: false,
        submitJobError: true,
      };
    }
    case SUBMIT_JOB_SUCCESS: {
      return {
        ...state,
        isSubmittingJob: false,
        submitJobSuccess: true,
      };
    }
    case SUBMIT_JOB_ERROR: {
      return {
        ...state,
        isSubmittingJob: false,
        submitJobError: true,
      };
    }
    default:
      return state;
  }
}
