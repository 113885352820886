import { RootState } from "../rootReducer";
import { UserState, UserDetails } from "./types";

export const getUserState = (store: RootState): UserState => store.user;

export const getIsSignedIn = (store: RootState): boolean =>
  getUserState(store).isSignedIn;

export const getUserId = (store: RootState): string | null =>
  getUserState(store).userId;

export const getIsAdminUserAllowed = (store: RootState): boolean | undefined =>
  getUserState(store).userDetails?.isAdminUserAllowed;

export const getUserCountry = (store: RootState): string | undefined =>
  getUserState(store).userDetails?.countryCode;

export const getShouldUseUpp = (store: RootState): boolean | undefined =>
  getUserState(store).userDetails?.shouldUseUpp;

export const getUserDetails = (store: RootState): UserDetails | undefined =>
  getUserState(store).userDetails;

export const getUserCredits = (store: RootState): number =>
  getUserState(store).remainingCredits;

export const getIsLoadingUserCredits = (store: RootState): boolean =>
  getUserState(store).isLoadingUserCredits;
