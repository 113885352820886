import React, { isValidElement } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boldText: {
      fontWeight: 600,
    },
    dialogHiddenHeader: {
      padding: theme.spacing(1),
      textAlign: "right",
      color: theme.palette.grey[500],
    },
    textMargin: {
      textAlign: "center",
    },
  })
);

interface MessageModalProps {
  title?: string;
  message?: string;
  isOpen: boolean;
  handleClose(visible: boolean): void;
  hideFooter?: boolean;
  hideCloseIcon?: boolean;
  icon?: JSX.Element;
  additionalInfo?: string;
  detailsList?: string[];
  closeButtonLabel?: string;
}

const MessageModal: React.FC<MessageModalProps> = ({
  title,
  message,
  isOpen: isMessageModalOpen,
  handleClose: setIsMessageModalOpen,
  hideFooter,
  hideCloseIcon,
  icon,
  additionalInfo,
  detailsList,
  closeButtonLabel,
}) => {
  const classes = useStyles();
  const { t } = useTranslation(["prospectSearch", "global"]);

  return (
    <Dialog
      data-testid="prospect-message-modal"
      fullWidth={true}
      open={isMessageModalOpen}
    >
      <DialogTitle
        classes={{
          root: !title ? classes.dialogHiddenHeader : "",
        }}
        disableTypography={true}
      >
        {title && (
          <Typography
            data-testid="message-modal-header-title"
            variant="body2"
            className={classes.boldText}
          >
            {title}
          </Typography>
        )}

        {!hideCloseIcon && (
          <IconButton
            data-testid="prospect-message-modal-close-icon-btn"
            size="small"
            onClick={() => setIsMessageModalOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent>
        {isValidElement(icon) && (
          <div className={classes.textMargin}>{icon}</div>
        )}
        <Typography
          className={!title && hideFooter ? classes.textMargin : ""}
          variant="body1"
        >
          {message}
        </Typography>
        {additionalInfo !== "" && (
          <Typography variant="body1" className={classes.textMargin}>
            {additionalInfo}
          </Typography>
        )}
        {detailsList && (
          <ul>
            {detailsList.map((l, i) => (
              <li key={`message-modal-list-item-${i}`}>{l}</li>
            ))}
          </ul>
        )}
      </DialogContent>
      {!hideFooter && (
        <DialogActions data-testid="message-modal-footer">
          <Button
            data-testid="prospect-message-modal-close-btn"
            color="primary"
            variant="contained"
            onClick={() => setIsMessageModalOpen(false)}
          >
            {closeButtonLabel || t("global:close")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default MessageModal;
