import { combineReducers } from "redux";
import user, { initialState as initialUserState } from "./user/reducer";
import i18n, { initialState as initialI18nState } from "./i18n/reducer";
import search, { initialState as initialSearchState } from "./search/reducer";
import order, { initialState as initialOrderState } from "./order/reducer";
import jobs, { initialState as initalJobsState } from "./jobs/reducer";
import portfolios, {
  initialState as initialPortfoliosState,
} from "./portfolios/reducer";
import criteria, {
  initialState as initialCriteriaState,
} from "./criteria/reducer";
import app, { initialState as initalAppState } from "./app/reducer";
import admin, { initialState as initialAdminState } from "./admin/reducer";

const rootReducer = combineReducers({
  user,
  i18n,
  search,
  order,
  jobs,
  portfolios,
  criteria,
  app,
  admin,
});

export type RootState = ReturnType<typeof rootReducer>;

export const initialState = {
  user: initialUserState,
  i18n: initialI18nState,
  search: initialSearchState,
  order: initialOrderState,
  jobs: initalJobsState,
  portfolios: initialPortfoliosState,
  criteria: initialCriteriaState,
  app: initalAppState,
  admin: initialAdminState,
};

export default rootReducer;
