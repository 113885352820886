import Loadable from "react-loadable";
import LoadingSpinner from "../LoadingSpinner";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MyLoadable = (opts: any) => {
  return Loadable({
    ...opts,
    loading: LoadingSpinner,
  });
};

export const AsyncPageNotFound = MyLoadable({
  loader: () => import("../../pages/PageNotFound"),
});

export const AsyncDashboard = MyLoadable({
  loader: () => import("../../pages/Dashboard"),
});

export const AsyncProspectSearch = MyLoadable({
  loader: () => import("../../pages/ProspectSearch"),
});

export const AsyncSuppressions = MyLoadable({
  loader: () => import("../../pages/Suppressions"),
});

export const AsyncAdmin = MyLoadable({
  loader: () => import("../../admin/Admin"),
});
