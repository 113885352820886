import {
  getAppLanguage,
  locationAlphabeticalOrderLanguageSpecific,
} from "../../appUtils";
import { CREATE_ORDER_SUCCESS, OrderActionTypes } from "../order/types";
import {
  DELETE_SEARCH_CRITERIA,
  SearchActionTypes,
  SET_SEARCH,
  SET_SEARCH_LOCATIONS,
  UPDATE_SEARCH_CRITERIA_ARRAY,
  UPDATE_SEARCH_CRITERIA_VALUE_ARRAY,
} from "../search/types";
import {
  FETCH_BUSINESS_CLASSIFICATIONS,
  FETCH_BUSINESS_CLASSIFICATIONS_ERROR,
  FETCH_BUSINESS_CLASSIFICATIONS_SUCCESS,
  CLEAR_HAS_CRITERIA_CHANGED,
  CLEAR_SAVED_SEARCHES,
  CriteriaActionTypes,
  CriteriaState,
  FETCH_ACTIVITY_CODES_BY_NAME,
  FETCH_ACTIVITY_CODES_BY_NAME_ERROR,
  FETCH_ACTIVITY_CODES_BY_NAME_SUCCESS,
  FETCH_ACTIVITY_CODES_BY_PARENT,
  FETCH_ACTIVITY_CODES_BY_PARENT_ERROR,
  FETCH_ACTIVITY_CODES_BY_PARENT_SUCCESS,
  FETCH_ACTIVITY_CODES_BY_ROOT,
  FETCH_ACTIVITY_CODES_BY_ROOT_ERROR,
  FETCH_ACTIVITY_CODES_BY_ROOT_SUCCESS,
  FETCH_ACTIVITY_TYPE,
  FETCH_ACTIVITY_TYPE_ERROR,
  FETCH_ACTIVITY_TYPE_SUCCESS,
  FETCH_LEGAL_FORMS,
  FETCH_LEGAL_FORMS_ERROR,
  FETCH_LEGAL_FORMS_SUCCESS,
  FETCH_LOCATIONS_BY_NAME,
  FETCH_LOCATIONS_BY_NAME_ERROR,
  FETCH_LOCATIONS_BY_NAME_SUCCESS,
  FETCH_LOCATIONS_BY_PARENT,
  FETCH_LOCATIONS_BY_PARENT_ERROR,
  FETCH_LOCATIONS_BY_PARENT_SUCCESS,
  FETCH_SAVED_SEARCHES,
  FETCH_SAVED_SEARCHES_ERROR,
  FETCH_SAVED_SEARCHES_SUCCESS,
  SAVE_SEARCH,
  SAVE_SEARCH_CLEAR,
  SAVE_SEARCH_ERROR,
  SAVE_SEARCH_SUCCESS,
  FETCH_COMPANY_AUDITORS,
  FETCH_COMPANY_AUDITORS_SUCCESS,
  FETCH_COMPANY_AUDITORS_ERROR,
} from "./types";

export const initialState: CriteriaState = {
  legalForms: [],
  isLoadingLegalForms: false,
  hasErrorLegalForms: false,
  businessClassifications: [],
  isLoadingBusinessClassifications: false,
  hasErrorBusinessClassifications: false,
  companyAuditors: [],
  isLoadingCompanyAuditors: false,
  hasErrorCompanyAuditors: false,
  savedSearches: [],
  totalSavedSearches: 0,
  isLoadingSavedSearches: false,
  isSavingSearch: false,
  hasErrorSaveSearch: false,
  searchSaved: false,
  hasCriteriaChanged: false,
  locations: {
    byName: {
      all: [],
      isLoading: false,
      hasError: false,
    },
    byParent: {
      all: [],
      isLoading: false,
      hasError: false,
    },
  },
  activityCodes: {
    type: {
      isLoading: false,
      hasError: false,
    },
    byName: {
      all: [],
      isLoading: false,
      hasError: false,
    },
    byParent: {
      all: [],
      isLoading: false,
      hasError: false,
    },
    byRoot: {
      all: [],
      isLoading: false,
      hasError: false,
    },
  },
};
// eslint-disable-next-line complexity
export default function (
  state = initialState,
  action: CriteriaActionTypes | SearchActionTypes | OrderActionTypes
): CriteriaState {
  switch (action.type) {
    case FETCH_LEGAL_FORMS: {
      return {
        ...state,
        legalForms: [],
        isLoadingLegalForms: true,
        hasErrorLegalForms: false,
      };
    }
    case FETCH_LEGAL_FORMS_SUCCESS: {
      return {
        ...state,
        legalForms: action.payload.results,
        isLoadingLegalForms: false,
      };
    }
    case FETCH_LEGAL_FORMS_ERROR: {
      return {
        ...state,
        isLoadingLegalForms: false,
        hasErrorLegalForms: true,
      };
    }
    case FETCH_BUSINESS_CLASSIFICATIONS: {
      return {
        ...state,
        businessClassifications: [],
        isLoadingBusinessClassifications: true,
        hasErrorBusinessClassifications: false,
      };
    }
    case FETCH_BUSINESS_CLASSIFICATIONS_SUCCESS: {
      return {
        ...state,
        businessClassifications: action.payload.results,
        isLoadingBusinessClassifications: false,
      };
    }
    case FETCH_BUSINESS_CLASSIFICATIONS_ERROR: {
      return {
        ...state,
        isLoadingBusinessClassifications: false,
        hasErrorBusinessClassifications: true,
      };
    }
    case FETCH_COMPANY_AUDITORS: {
      return {
        ...state,
        companyAuditors: [],
        isLoadingCompanyAuditors: true,
        hasErrorCompanyAuditors: false,
      };
    }
    case FETCH_COMPANY_AUDITORS_SUCCESS: {
      return {
        ...state,
        companyAuditors: action.payload.results,
        isLoadingCompanyAuditors: false,
      };
    }
    case FETCH_COMPANY_AUDITORS_ERROR: {
      return {
        ...state,
        isLoadingCompanyAuditors: false,
        hasErrorCompanyAuditors: true,
      };
    }
    case FETCH_SAVED_SEARCHES: {
      return {
        ...state,
        isLoadingSavedSearches: true,
      };
    }
    case FETCH_SAVED_SEARCHES_SUCCESS: {
      return {
        ...state,
        savedSearches: action.payload.savedSearches,
        totalSavedSearches: action.payload.total,
        isLoadingSavedSearches: false,
      };
    }
    case FETCH_SAVED_SEARCHES_ERROR: {
      return {
        ...state,
        isLoadingSavedSearches: false,
      };
    }
    case CLEAR_SAVED_SEARCHES: {
      return {
        ...state,
        savedSearches: [],
        totalSavedSearches: 0,
      };
    }
    case SAVE_SEARCH: {
      return {
        ...state,
        isSavingSearch: true,
        hasErrorSaveSearch: false,
        searchSaved: false,
      };
    }
    case SAVE_SEARCH_SUCCESS: {
      return {
        ...state,
        isSavingSearch: false,
        searchSaved: true,
        hasCriteriaChanged: false,
      };
    }
    case SAVE_SEARCH_ERROR: {
      return {
        ...state,
        isSavingSearch: false,
        hasErrorSaveSearch: true,
      };
    }
    case SAVE_SEARCH_CLEAR: {
      return {
        ...state,
        searchSaved: false,
        hasErrorSaveSearch: false,
      };
    }
    case SET_SEARCH:
    case DELETE_SEARCH_CRITERIA:
    case UPDATE_SEARCH_CRITERIA_VALUE_ARRAY:
    case UPDATE_SEARCH_CRITERIA_ARRAY: {
      return {
        ...state,
        hasCriteriaChanged: true,
      };
    }
    case SET_SEARCH_LOCATIONS: {
      return {
        ...state,
        hasCriteriaChanged: true,
        activityCodes: {
          ...state.activityCodes,
          type: {
            ...state.activityCodes.type,
          },
          byRoot: {
            ...state.activityCodes.byRoot,
            all: [],
          },
          byParent: {
            ...state.activityCodes.byParent,
            all: [],
          },
          byName: {
            ...state.activityCodes.byName,
            all: [],
          },
        },
      };
    }
    case CLEAR_HAS_CRITERIA_CHANGED:
    case CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        hasCriteriaChanged: false,
      };
    }
    case FETCH_LOCATIONS_BY_NAME:
      return {
        ...state,
        locations: {
          ...state.locations,
          byName: {
            ...state.locations.byName,
            isLoading: true,
            hasError: false,
          },
        },
      };
    case FETCH_LOCATIONS_BY_NAME_SUCCESS:
      return {
        ...state,
        locations: {
          ...state.locations,
          byName: {
            ...state.locations.byName,
            all: action.payload,
            isLoading: false,
          },
        },
      };
    case FETCH_LOCATIONS_BY_NAME_ERROR:
      return {
        ...state,
        locations: {
          ...state.locations,
          byName: {
            ...state.locations.byName,
            isLoading: false,
            hasError: true,
          },
        },
      };
    case FETCH_LOCATIONS_BY_PARENT:
      return {
        ...state,
        locations: {
          ...state.locations,
          byParent: {
            ...state.locations.byParent,
            isLoading: true,
            hasError: false,
          },
        },
      };
    case FETCH_LOCATIONS_BY_PARENT_SUCCESS: {
      const language = getAppLanguage();
      const locations = locationAlphabeticalOrderLanguageSpecific(
        action.payload,
        language
      );

      return {
        ...state,
        locations: {
          ...state.locations,
          byParent: {
            ...state.locations.byParent,
            all: locations,
            isLoading: false,
          },
        },
      };
    }
    case FETCH_LOCATIONS_BY_PARENT_ERROR:
      return {
        ...state,
        locations: {
          ...state.locations,
          byParent: {
            ...state.locations.byParent,
            isLoading: false,
            hasError: true,
          },
        },
      };
    case FETCH_ACTIVITY_TYPE:
      return {
        ...state,
        activityCodes: {
          ...state.activityCodes,
          type: {
            ...state.activityCodes.type,
            isLoading: true,
            hasError: false,
          },
        },
      };
    case FETCH_ACTIVITY_TYPE_SUCCESS:
      return {
        ...state,
        activityCodes: {
          ...state.activityCodes,
          type: {
            ...state.activityCodes.type,
            isLoading: false,
          },
        },
      };
    case FETCH_ACTIVITY_TYPE_ERROR:
      return {
        ...state,
        activityCodes: {
          ...state.activityCodes,
          type: {
            ...state.activityCodes.type,
            isLoading: false,
            hasError: true,
          },
        },
      };
    case FETCH_ACTIVITY_CODES_BY_ROOT:
      return {
        ...state,
        activityCodes: {
          ...state.activityCodes,
          byRoot: {
            ...state.activityCodes.byRoot,
            isLoading: true,
            hasError: false,
          },
        },
      };
    case FETCH_ACTIVITY_CODES_BY_ROOT_SUCCESS:
      return {
        ...state,
        activityCodes: {
          ...state.activityCodes,
          byRoot: {
            ...state.activityCodes.byRoot,
            all: action.payload,
            isLoading: false,
          },
        },
      };
    case FETCH_ACTIVITY_CODES_BY_ROOT_ERROR:
      return {
        ...state,
        activityCodes: {
          ...state.activityCodes,
          byRoot: {
            ...state.activityCodes.byRoot,
            isLoading: false,
            hasError: true,
          },
        },
      };
    case FETCH_ACTIVITY_CODES_BY_PARENT:
      return {
        ...state,
        activityCodes: {
          ...state.activityCodes,
          byParent: {
            ...state.activityCodes.byParent,
            isLoading: true,
            hasError: false,
          },
        },
      };
    case FETCH_ACTIVITY_CODES_BY_PARENT_SUCCESS:
      return {
        ...state,
        activityCodes: {
          ...state.activityCodes,
          byParent: {
            ...state.activityCodes.byParent,
            all: action.payload,
            isLoading: false,
          },
        },
      };
    case FETCH_ACTIVITY_CODES_BY_PARENT_ERROR:
      return {
        ...state,
        activityCodes: {
          ...state.activityCodes,
          byParent: {
            ...state.activityCodes.byParent,
            isLoading: false,
            hasError: true,
          },
        },
      };
    case FETCH_ACTIVITY_CODES_BY_NAME:
      return {
        ...state,
        activityCodes: {
          ...state.activityCodes,
          byName: {
            ...state.activityCodes.byName,
            isLoading: true,
            hasError: false,
          },
        },
      };
    case FETCH_ACTIVITY_CODES_BY_NAME_SUCCESS:
      return {
        ...state,
        activityCodes: {
          ...state.activityCodes,
          byName: {
            ...state.activityCodes.byName,
            all: action.payload,
            isLoading: false,
          },
        },
      };
    case FETCH_ACTIVITY_CODES_BY_NAME_ERROR:
      return {
        ...state,
        activityCodes: {
          ...state.activityCodes,
          byName: {
            ...state.activityCodes.byName,
            isLoading: false,
            hasError: true,
          },
        },
      };
    default:
      return state;
  }
}
