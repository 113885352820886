import React from "react";
import { Switch, Route } from "react-router-dom";
import AppLayout from "./containers/AppLayout";
import { useSelector } from "react-redux";
import { getIsSignedIn } from "./store/user/selectors";
import SignInLoading from "./pages/SignInLoading";
import {
  ReactPlugin,
  withAITracking,
} from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import config, { updateConfig } from "./config";
import { initTracking, ITrackingConfig } from "@creditsafe/tracking";
import { SplitTreatments } from "@splitsoftware/splitio-react";
const reactPlugin = new ReactPlugin();
const cloudFeatureName = "UseAzure_DataCleaning";
initTracking({
  instrumentationKey: config.INSTRUMENTATION_KEY,
  extensions: [reactPlugin],
  extensionConfig: {
    [reactPlugin.identifier]: {
      history: createBrowserHistory({ basename: "" }),
    },
  },
} as ITrackingConfig);

const App: React.FC = () => {
  const isSignedIn = useSelector(getIsSignedIn);

  if (!isSignedIn) {
    return <SignInLoading />;
  }
  return (
    <SplitTreatments names={[cloudFeatureName]}>
      {({ treatments, isReady }) => {
        if (isReady && treatments[cloudFeatureName].treatment === "on") {
          updateConfig(true);
        }
        return (
          <Switch>
            <Route component={AppLayout} />
          </Switch>
        );
      }}
    </SplitTreatments>
  );
};

export default withAITracking(reactPlugin, App);
