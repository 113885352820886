import React from "react";
import { useTranslation } from "react-i18next";
// import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Auth from "@creditsafe/upp-authentication";
import MuiAppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";

import { ReactComponent as Cslogo } from "../../assets/cslogo.svg";
import cslogoMini from "../../assets/csLogoMini.svg";
// import { ReactComponent as ProfileIcon } from "../../assets/profile.svg";
// import config from "../../config";
// import { getUserId } from "../../store/user/selectors";
import LanguageSwitcher from "./LanguageSwitcher";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoContainer: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    iconNamePair: {
      display: "flex",
      alignItems: "center",
      "&>span>svg": {
        margin: theme.spacing(0, 1),
      },
    },
    logo: {
      display: "flex",
      alignItems: "center",
      width: "25px",
      textDecoration: "none",
      [theme.breakpoints.up("sm")]: {
        width: "300px",
      },
    },
    multilineButtonText: {
      lineHeight: 1.2,
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
    },
    smallText: {
      fontSize: "0.8em",
    },
    button: {
      marginRight: "2px",
    },
  })
);

interface AppBarProps {
  onToggle(): void;
}

const AppBar: React.FC<AppBarProps> = ({ onToggle }) => {
  const classes = useStyles();
  const { t } = useTranslation("global");
  // const userId = useSelector(getUserId);

  return (
    <MuiAppBar position="fixed" color="default">
      <Toolbar>
        <div className={classes.logoContainer}>
          <Link to="/" className={"brand " + classes.logo}>
            <Hidden xsDown>
              <Cslogo width="198px" height="50px" />
            </Hidden>
            <Hidden smUp>
              <img
                style={{ width: "25px" }}
                src={cslogoMini}
                alt="Creditsafe"
              />
            </Hidden>
            <Typography color="textPrimary" variant="h6">
              {t("prospects")}
            </Typography>
          </Link>
        </div>

        <Hidden xsDown>
          {/* <Button
            className={classes.button}
            data-testid="my-account-button"
            aria-label={t("sidebar.myaccount")}
            color="inherit"
            component={"a"}
            href={`${config.UPP_APP_URL}/account`}
          >
            <svg height="30" width="40">
              <ProfileIcon />
            </svg>
            <div className={classes.multilineButtonText}>
              {t("sidebar.myaccount")}
              <span className={classes.smallText}>
                <strong>{t("sidebar.userId")}</strong>
                {userId}
              </span>
            </div>
          </Button> */}
          <LanguageSwitcher />
          <Button
            data-testid="btn-signout"
            color="inherit"
            onClick={() => Auth.signOut()}
            className={classes.iconNamePair}
          >
            <ExitToAppIcon />
            {t("signOut")}
          </Button>
        </Hidden>
        <Hidden smUp>
          <LanguageSwitcher />
          <IconButton
            aria-label={t("openDrawer")}
            onClick={onToggle}
            color="inherit"
            data-testid="hamburgerMenuButton"
            edge="end"
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
