import React from "react";
import { Route } from "react-router-dom";
import {
  AsyncDashboard,
  AsyncPageNotFound,
  AsyncProspectSearch,
  AsyncSuppressions,
  AsyncAdmin,
} from "./LoadableRoutes";

export interface UrlParams {
  orderId: string;
}

const getAppRoutes = () => {
  return [
    <Route exact path="/" component={AsyncDashboard} key="home-route" />,
    <Route
      exact
      path="/prospectSearch"
      component={AsyncProspectSearch}
      key="search-route"
    />,
    <Route
      path="/prospectSearch/:orderId"
      component={AsyncProspectSearch}
      key="search-id-route"
    />,
    <Route
      exact
      path="/suppressions"
      component={AsyncSuppressions}
      key="suppressions-route"
    />,
    <Route exact path="/admin" component={AsyncAdmin} key="admin-route" />,
    <Route component={AsyncPageNotFound} key="not-found-route" />,
  ];
};

export default getAppRoutes;
