import * as JobActions from "./actions";
import { ActionType } from "typesafe-actions";
import { Mapping } from "@creditsafe/portfolios-shared-components/dist/components/Mappings/types";

export const FETCH_JOBS = "FETCH_JOBS";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOBS_ERROR = "FETCH_JOBS_ERROR";

export const FETCH_JOB_DETAILS = "FETCH_JOB_DETAILS";
export const FETCH_JOB_DETAILS_SUCCESS = "FETCH_JOB_DETAILS_SUCCESS";
export const FETCH_JOB_DETAILS_ERROR = "FETCH_JOB_DETAILS_ERROR";

export const FETCH_JOB_SAMPLE = "FETCH_JOB_SAMPLE";
export const FETCH_JOB_SAMPLE_SUCCESS = "FETCH_JOB_SAMPLE_SUCCESS";
export const FETCH_JOB_SAMPLE_ERROR = "FETCH_JOB_SAMPLE_ERROR";

export const CREATE_JOB = "CREATE_JOB";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_ERROR = "CREATE_JOB_ERROR";

export const SAVE_JOB_MAPPINGS = "SAVE_JOB_MAPPINGS";
export const SAVE_JOB_MAPPINGS_SUCCESS = "SAVE_JOB_MAPPINGS_SUCCESS";
export const SAVE_JOB_MAPPINGS_ERROR = "SAVE_JOB_MAPPINGS_ERROR";

export const SUBMIT_JOB = "SUBMIT_JOB";
export const SUBMIT_JOB_SUCCESS = "SUBMIT_JOB_SUCCESS";
export const SUBMIT_JOB_ERROR = "SUBMIT_JOB_ERROR";

export const SET_MAPPING_JOB = "SET_MAPPING_JOB";
export const CLEAR_MAPPING_JOB = "CLEAR_MAPPING_JOB";

export enum JobSource {
  prospects = "Prospects",
}

export interface JobFileDetails {
  id: string;
  hasHeader: boolean;
}

export interface MappingDetails {
  job: Job | null;
  sample: string[][] | null;
  fileDetails: JobFileDetails | null;
  isLoading: boolean;
  hasError: boolean;
}

export interface JobsState {
  jobs: Job[];
  totalJobs: number;
  isLoadingJobs: boolean;
  isCreatingJob: boolean;
  createJobSuccess: boolean;
  createJobError: CreateJobErrors | null;
  isSubmittingJob: boolean;
  submitJobSuccess: boolean;
  submitJobError: boolean;
  mappingDetails: MappingDetails;
}

export type FetchJobsPayload = {
  page: number;
  limit: number;
};

export type FetchJobsResponse = {
  jobs: Job[];
  total: number;
};

export interface CreateJobPayload {
  jobDetails: {
    name: string;
    person: string;
    telephone?: string;
    email: string;
    jobFile: File;
    hasHeader: boolean;
    source: JobSource;
  };
}

export type FetchJobPayload = {
  jobId: string;
};

export type FetchJobSamplePayload = {
  jobId: string;
  page: number;
  limit: number;
};

export type FetchJobSampleResponse = {
  sample: string[][];
  fileDetails: JobFileDetails;
};

export type CreateJobErrors = "JobNameNotUnique" | "UnknownError" | "Forbidden";

export type Job = {
  id: string;
  name: string;
  owningCustomerId: number;
  createdAt: Date;
  status: JobStatus;
  source: JobSource;
  jobSummary?: JobSummary;
};

export type JobStatus =
  | "created"
  | "uploading"
  | "uploadError"
  | "uploaded"
  | "ready"
  | "matching"
  | "matchingError"
  | "matched"
  | "dataAppending"
  | "appendedError"
  | "appended";

export type JobSummary = {
  matched: number;
  totalRows: number;
};

export type UpdateJobMappingsPayload = {
  jobId: string;
  mappings: Mapping[];
  fileId: string;
  hasHeader: boolean;
};

export type JobActionTypes = ActionType<typeof JobActions>;
