import * as PortfolioActions from "./actions";
import { ActionType } from "typesafe-actions";

export const FETCH_PORTFOLIOS = "FETCH_PORTFOLIOS";
export const FETCH_PORTFOLIOS_SUCCESS = "FETCH_PORTFOLIOS_SUCCESS";
export const FETCH_PORTFOLIOS_BY_ID = "FETCH_PORTFOLIOS_BY_ID";

export enum PortfolioCategories {
  prospectRequest = "ProspectRequest",
  dataCleaning = "DataCleaning",
}

export interface PortfoliosState {
  portfolios: Portfolio[];
}

export type FetchPortfoliosPayload = {
  _page?: number;
  _limit?: number;
  categories?: PortfolioCategories[];
};

export type FetchPortfoliosResponse = {
  portfolios: Portfolio[];
  count: number;
};

export type FetchPortfolioPayload = {
  portfolioId: string;
};

export type FetchPortfolioResponse = Portfolio;

export type Portfolio = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  userId: number;
  customerId: number;
  categories: PortfolioCategories[];
  subjectCount: number;
};

export type PortfoliosActionTypes = ActionType<typeof PortfolioActions>;
