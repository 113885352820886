import {
  DELETE_SEARCH_CRITERIA,
  RESET_SEARCH,
  SearchActionTypes,
  SET_SEARCH,
  SET_SEARCH_INTERNATIONAL_ACTIVITY,
  SET_SEARCH_LOCAL_ACTIVITY,
  SET_SEARCH_LOCATIONS,
  UPDATE_SEARCH_CRITERIA_ARRAY,
  UPDATE_SEARCH_CRITERIA_VALUE_ARRAY,
} from "../search/types";
import {
  CLEAR_ORDERS,
  CREATE_ORDER,
  CREATE_ORDER_ERROR,
  CREATE_ORDER_SUCCESS,
  DOWNLOAD_PROSPECT_LIST,
  DOWNLOAD_PROSPECT_LIST_ERROR,
  DOWNLOAD_PROSPECT_LIST_SUCCESS,
  FETCH_AMOUNT_PER_COUNTRY,
  FETCH_AMOUNT_PER_COUNTRY_ERROR,
  FETCH_AMOUNT_PER_COUNTRY_SUCCESS,
  FETCH_COMPANIES_COUNT,
  FETCH_COMPANIES_COUNT_ERROR,
  FETCH_COMPANIES_COUNT_SUCCESS,
  FETCH_NONLTD_CREDITS_PER_COUNTRY,
  FETCH_NONLTD_CREDITS_PER_COUNTRY_ERROR,
  FETCH_NONLTD_CREDITS_PER_COUNTRY_SUCCESS,
  FETCH_ORDERS,
  FETCH_ORDERS_ERROR,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDER_INFO,
  FETCH_ORDER_INFO_ERROR,
  FETCH_ORDER_INFO_SUCCESS,
  FETCH_ORDER_PROSPECTS,
  FETCH_ORDER_PROSPECTS_ERROR,
  FETCH_ORDER_PROSPECTS_SUCCESS,
  FETCH_ORDER_PROSPECT_INFO,
  FETCH_ORDER_PROSPECT_INFO_ERROR,
  FETCH_ORDER_PROSPECT_INFO_SUCCESS,
  FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT,
  FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT_ERROR,
  FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT_SUCCESS,
  OrderActionTypes,
  OrderState,
  RESET_PROSPECT_LIST_FILE_PATH,
} from "./types";

export const initialState: OrderState = {
  companiesCount: null,
  isLoadingCompaniesCount: false,
  hasCompaniesCountError: false,
  orders: [],
  totalOrders: 0,
  createOrderIsLoading: false,
  createOrderError: null,
  createdOrderSuccess: false,
  amountPerCountry: {},
  isLoadingAmountPerCountry: false,
  orderInfo: null,
  hasOrderInfoError: false,
  orderProspects: [],
  totalOrderProspects: 0,
  orderProspectsContinuationToken: [""],
  isLoadingProspects: false,
  isLoadingOrderProspects: false,
  orderProspectInfo: null,
  hasOrderProspectInfoError: false,
  isLoadingOrderProspectInfo: false,
  downloadedOrderId: null,
  isLoadingProspectsExport: false,
  hasProspectsExportError: false,
  prospectListFilePath: null,
  isLoadingExcludedCompaniesPreviousOrdersCount: false,
  excludedCompaniesPreviousOrdersCount: 0,
  usedCreditsForNonLtd: {},
  isLoadingUsedCreditsForNonLtd: false,
};
// eslint-disable-next-line complexity
export default function (
  state = initialState,
  action: OrderActionTypes | SearchActionTypes
): OrderState {
  switch (action.type) {
    case FETCH_COMPANIES_COUNT: {
      return {
        ...state,
        isLoadingCompaniesCount: true,
        hasCompaniesCountError: false,
      };
    }
    case FETCH_COMPANIES_COUNT_SUCCESS: {
      return {
        ...state,
        companiesCount: action.payload.total,
        isLoadingCompaniesCount: false,
        hasCompaniesCountError: false,
      };
    }
    case FETCH_COMPANIES_COUNT_ERROR: {
      return {
        ...state,
        companiesCount: null,
        isLoadingCompaniesCount: false,
        hasCompaniesCountError: true,
      };
    }
    case FETCH_AMOUNT_PER_COUNTRY: {
      return {
        ...state,
        amountPerCountry: {},
        isLoadingAmountPerCountry: true,
        createOrderError: null,
        createOrderIsLoading: false,
        createdOrderSuccess: false,
      };
    }
    case FETCH_AMOUNT_PER_COUNTRY_SUCCESS: {
      return {
        ...state,
        amountPerCountry: action.payload.amountPerCountry,
        isLoadingAmountPerCountry: false,
      };
    }
    case FETCH_AMOUNT_PER_COUNTRY_ERROR: {
      return {
        ...state,
        amountPerCountry: {},
        isLoadingAmountPerCountry: false,
      };
    }
    case FETCH_NONLTD_CREDITS_PER_COUNTRY: {
      return {
        ...state,
        usedCreditsForNonLtd: {},
        isLoadingUsedCreditsForNonLtd: true,
      };
    }
    case FETCH_NONLTD_CREDITS_PER_COUNTRY_SUCCESS: {
      return {
        ...state,
        usedCreditsForNonLtd: action.payload,
        isLoadingUsedCreditsForNonLtd: false,
      };
    }
    case FETCH_NONLTD_CREDITS_PER_COUNTRY_ERROR: {
      return {
        ...state,
        usedCreditsForNonLtd: {},
        isLoadingUsedCreditsForNonLtd: false,
      };
    }
    case SET_SEARCH:
    case SET_SEARCH_LOCATIONS:
    case SET_SEARCH_INTERNATIONAL_ACTIVITY:
    case SET_SEARCH_LOCAL_ACTIVITY:
    case UPDATE_SEARCH_CRITERIA_VALUE_ARRAY:
    case UPDATE_SEARCH_CRITERIA_ARRAY: {
      return {
        ...state,
        companiesCount: null,
        isLoadingCompaniesCount: false,
        hasCompaniesCountError: false,
      };
    }
    case DELETE_SEARCH_CRITERIA: {
      const newState = {
        ...state,
        companiesCount: null,
        isLoadingCompaniesCount: false,
        hasCompaniesCountError: false,
      };
      if (action.payload.key === "exclusions.excludePreviousOrdersDuration") {
        newState.excludedCompaniesPreviousOrdersCount = 0;
      }
      return newState;
    }
    case RESET_SEARCH: {
      return {
        ...state,
        companiesCount: null,
        isLoadingCompaniesCount: false,
        hasCompaniesCountError: false,
        excludedCompaniesPreviousOrdersCount: 0,
      };
    }
    case FETCH_ORDERS: {
      return {
        ...state,
        orderInfo: null,
        orderProspects: [],
        totalOrderProspects: 0,
        orderProspectsContinuationToken: [""],
        hasProspectsExportError: false,
        isLoadingProspectsExport: false,
      };
    }
    case FETCH_ORDERS_SUCCESS: {
      return {
        ...state,
        orders: [...state.orders, ...action.payload.orders],
        totalOrders: action.payload.totalOrders,
      };
    }
    case FETCH_ORDERS_ERROR: {
      return state;
    }
    case CLEAR_ORDERS: {
      return {
        ...state,
        orders: [],
        totalOrders: 0,
      };
    }
    case CREATE_ORDER: {
      return {
        ...state,
        createOrderIsLoading: true,
        createdOrderSuccess: false,
        createOrderError: null,
      };
    }
    case CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        createOrderIsLoading: false,
        createdOrderSuccess: true,
      };
    }
    case CREATE_ORDER_ERROR: {
      return {
        ...state,
        createOrderIsLoading: false,
        createdOrderSuccess: false,
        createOrderError: action.payload,
      };
    }
    case FETCH_ORDER_INFO: {
      return {
        ...state,
        hasOrderInfoError: false,
        isLoadingOrderProspects: true,
        orderProspectsContinuationToken: [""],
      };
    }
    case FETCH_ORDER_INFO_SUCCESS: {
      return {
        ...state,
        orderInfo: action.payload,
      };
    }
    case FETCH_ORDER_INFO_ERROR: {
      return {
        ...state,
        hasOrderInfoError: true,
        isLoadingOrderProspects: false,
      };
    }
    case FETCH_ORDER_PROSPECTS: {
      if (action.payload.page === 0) {
        return {
          ...state,
          orderProspectsContinuationToken: [""],
          isLoadingProspects: true,
        };
      }
      return {
        ...state,
        isLoadingProspects: true,
      };
    }
    case FETCH_ORDER_PROSPECTS_SUCCESS: {
      return {
        ...state,
        orderProspects: action.payload.prospects,
        totalOrderProspects: action.payload.totalProspects,
        orderProspectsContinuationToken: [
          ...state.orderProspectsContinuationToken,
          action.payload.continuationToken,
        ],
        isLoadingProspects: false,
        isLoadingOrderProspects: false,
      };
    }
    case FETCH_ORDER_PROSPECTS_ERROR: {
      return {
        ...state,
        isLoadingProspects: false,
        isLoadingOrderProspects: false,
      };
    }
    case FETCH_ORDER_PROSPECT_INFO: {
      return {
        ...state,
        hasOrderProspectInfoError: false,
        isLoadingOrderProspectInfo: true,
      };
    }
    case FETCH_ORDER_PROSPECT_INFO_SUCCESS: {
      return {
        ...state,
        orderProspectInfo: action.payload,
        isLoadingOrderProspectInfo: false,
      };
    }
    case FETCH_ORDER_PROSPECT_INFO_ERROR: {
      return {
        ...state,
        hasOrderProspectInfoError: true,
        isLoadingOrderProspectInfo: false,
      };
    }
    case DOWNLOAD_PROSPECT_LIST: {
      return {
        ...state,
        downloadedOrderId: action.payload.orderId,
        hasProspectsExportError: false,
        isLoadingProspectsExport: true,
        prospectListFilePath: null,
      };
    }
    case DOWNLOAD_PROSPECT_LIST_SUCCESS: {
      return {
        ...state,
        downloadedOrderId: null,
        prospectListFilePath: action.payload,
        isLoadingProspectsExport: false,
      };
    }
    case DOWNLOAD_PROSPECT_LIST_ERROR: {
      return {
        ...state,
        hasProspectsExportError: true,
        isLoadingProspectsExport: false,
        prospectListFilePath: null,
      };
    }
    case RESET_PROSPECT_LIST_FILE_PATH: {
      return {
        ...state,
        prospectListFilePath: null,
      };
    }
    case FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT: {
      return {
        ...state,
        isLoadingExcludedCompaniesPreviousOrdersCount: true,
      };
    }
    case FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT_SUCCESS: {
      return {
        ...state,
        excludedCompaniesPreviousOrdersCount: action.payload.count,
        isLoadingExcludedCompaniesPreviousOrdersCount: false,
      };
    }
    case FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT_ERROR: {
      return {
        ...state,
        excludedCompaniesPreviousOrdersCount: 0,
        isLoadingExcludedCompaniesPreviousOrdersCount: false,
      };
    }
    default:
      return state;
  }
}
