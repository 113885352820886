import * as UserActions from "./actions";
import { ActionType } from "typesafe-actions";

export const USER_SIGNED_IN = "USER_SIGNED_IN";

// User specfic details
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";
export const FETCH_USER_DETAILS_SUCCESS = "FETCH_USER_DETAILS_SUCCESS";
export const FETCH_USER_DETAILS_ERROR = "FETCH_USER_DETAILS_ERROR";

export const FETCH_ADMIN_USER = "FETCH_ADMIN_USER";
export const FETCH_ADMIN_USER_SUCCESS = "FETCH_ADMIN_USER_SUCCESS";
export const FETCH_ADMIN_USER_ERROR = "FETCH_ADMIN_USER_ERROR";

// Entity Specfic Details for User
export const FETCH_USER_ENTITY_DETAILS = "FETCH_USER_ENTITY_DETAILS";
export const FETCH_USER_ENTITY_DETAILS_SUCCESS =
  "FETCH_USER_ENTITY_DETAILS_SUCCESS";
export const FETCH_USER_ENTITY_DETAILS_ERROR =
  "FETCH_USER_ENTITY_DETAILS_ERROR";

// Save Lang
export const PUT_USER_LANG = "PUT_USER_LANG";
export const PUT_USER_LANG_SUCCESS = "PUT_USER_LANG_SUCCESS";
export const PUT_USER_LANG_ERROR = "PUT_USER_LANG_ERROR";

// User specfic details
export const FETCH_USER_CREDITS = "FETCH_USER_CREDITS";
export const FETCH_USER_CREDITS_SUCCESS = "FETCH_USER_CREDITS_SUCCESS";
export const FETCH_USER_CREDITS_ERROR = "FETCH_USER_CREDITS_ERROR";

export interface Contacts {
  title: string;
  firstName: string;
  surname: string;
}

export interface Customer {
  companyName: string;
  contact: Contacts;
  emailAddress: string;
  id: number;
  phoneNumber: string;
}

export interface UserDetails {
  contact?: Contacts;
  customer?: Customer;
  countryCode?: string;
  emailAddress?: string;
  id?: number;
  languageCode?: string;
  lastLoginDate?: string;
  registrationNumber?: string;
  username?: string;
  shouldUseUpp?: boolean;
  isAdminUserAllowed?: boolean;
}

export interface UserEntityDetails {
  address: string;
  email: string;
  entityName: string;
  tel: string;
}

export interface UserState {
  userId: string | null;
  isSignedIn: boolean;
  userDetails?: UserDetails;
  entityInfo?: UserEntityDetails;
  isLoadingUserCredits: boolean;
  remainingCredits: number;
}

export type UserSignedInPayload = string;
export type UserDetailsPayload = UserDetails;
export type UserEntityDetailsPayload = UserEntityDetails;

export interface UserSignedInAction {
  type: typeof USER_SIGNED_IN;
  payload: UserSignedInPayload;
}

export type FetchUserCreditsResponse = {
  remainingLimit: number;
};

export type UserActionTypes = ActionType<typeof UserActions>;
