import { SET_LOCALES, I18nActionsTypes, I18nState } from "./types";
import { locales } from "../../config/i18n-client";

export const initialState: I18nState = {
  locales,
};

export const i18nReducer = (state = initialState, action: I18nActionsTypes) => {
  switch (action.type) {
    case SET_LOCALES:
      return {
        ...state,
        locales: action.payload,
      };
    default:
      return state;
  }
};

export default i18nReducer;
