import { Epic } from "../rootEpic";
import { of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";
import { mapSearchCriteriaToApiPayload } from "../../utils/searchCriteriaMapping";
import { IConfig } from "../../config";
import {
  DEFAULT_PROSPECTS_LIMIT,
  DEFAULT_PROSPECTS_PAGE,
} from "../../constants";
import { RequestType } from "../epicDependencies";
import { getTotalCountHeader } from "../utils/getTotalCountHeader";
import {
  createOrderError,
  createOrderSuccess,
  downloadProspectListError,
  downloadProspectListSuccess,
  fetchAmountPerCountryError,
  fetchAmountPerCountrySuccess,
  fetchCompaniesCountError,
  fetchCompaniesCountSuccess,
  fetchPreviousExcludedCompaniesCountSuccess,
  fetchOrderInfoError,
  fetchOrderInfoSuccess,
  fetchOrderProspectInfoError,
  fetchOrderProspectInfoSuccess,
  fetchOrderProspects,
  fetchOrderProspectsError,
  fetchOrderProspectsSuccess,
  fetchOrdersError,
  fetchOrdersSuccess,
  fetchPreviousExcludedCompaniesCountError,
  fetchNonLtdUsedCreditsPerCountryError,
  fetchNonLtdUsedCreditsPerCountrySuccess,
} from "./actions";
import {
  CREATE_ORDER,
  DOWNLOAD_PROSPECT_LIST,
  FETCH_AMOUNT_PER_COUNTRY,
  FETCH_COMPANIES_COUNT,
  FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT,
  FETCH_ORDERS,
  FETCH_ORDER_INFO,
  FETCH_ORDER_PROSPECTS,
  FETCH_ORDER_PROSPECT_INFO,
  FETCH_NONLTD_CREDITS_PER_COUNTRY,
} from "./types";
import { FlattenedSearchState } from "../search/types";

export const fetchOrderInfoEpic: Epic<
  | ActionType<typeof fetchOrderInfoSuccess>
  | ActionType<typeof fetchOrderProspects>
  | ActionType<typeof fetchOrderInfoError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_ORDER_INFO)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/orders/${payload.orderId}`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }).pipe(
        switchMap(({ response }) =>
          of(
            fetchOrderInfoSuccess(response),
            fetchOrderProspects({
              orderId: payload.orderId,
              page: DEFAULT_PROSPECTS_PAGE,
              limit: DEFAULT_PROSPECTS_LIMIT,
            })
          )
        ),
        catchError(() => of(fetchOrderInfoError()))
      )
    )
  );

export const fetchOrderProspectsEpic: Epic<
  | ActionType<typeof fetchOrderProspectsSuccess>
  | ActionType<typeof fetchOrderProspectsError>
> = (action$, state$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_ORDER_PROSPECTS)),
    withLatestFrom(state$),
    switchMap(([{ payload }, { order }]) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/orders/${payload.orderId}/prospects?limit=${payload.limit}`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          "X-Continuation-Token":
            payload.page === 0
              ? null
              : order.orderProspectsContinuationToken[payload.page],
        },
      }).pipe(
        switchMap((response) => {
          const count = getTotalCountHeader(response);
          const continuationToken =
            response.xhr.getResponseHeader("X-Continuation-Token") || "";
          const results = response.response;
          return of(
            fetchOrderProspectsSuccess({
              prospects: results,
              totalProspects: parseInt(count, 10),
              continuationToken,
            })
          );
        }),
        catchError(() => of(fetchOrderProspectsError()))
      )
    )
  );

export const fetchOrdersEpic: Epic<
  ActionType<typeof fetchOrdersSuccess> | ActionType<typeof fetchOrdersError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_ORDERS)),
    mergeMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/orders?page=${payload.page}&limit=${payload.limit}`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }).pipe(
        switchMap((response) => {
          const count = getTotalCountHeader(response);
          const results = response.response;
          return of(
            fetchOrdersSuccess({
              orders: results,
              totalOrders: parseInt(count, 10),
            })
          );
        }),
        catchError(() => of(fetchOrdersError()))
      )
    )
  );

export const fetchAmountPerCountryEpic: Epic<
  | ActionType<typeof fetchAmountPerCountrySuccess>
  | ActionType<typeof fetchAmountPerCountryError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_AMOUNT_PER_COUNTRY)),
    exhaustMap(({ payload }) =>
      fetchCounts(request, config, payload).pipe(
        map(({ response }) => fetchAmountPerCountrySuccess(response)),
        catchError(() => of(fetchAmountPerCountryError()))
      )
    )
  );

export const fetchNonLtdUsedCreditsPerCountryEpic: Epic<
  | ActionType<typeof fetchNonLtdUsedCreditsPerCountrySuccess>
  | ActionType<typeof fetchNonLtdUsedCreditsPerCountryError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_NONLTD_CREDITS_PER_COUNTRY)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/Orders/_nonLimitedUsedCredits?countries=${payload}`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }).pipe(
        map(({ response }) =>
          fetchNonLtdUsedCreditsPerCountrySuccess(response)
        ),
        catchError(() => of(fetchNonLtdUsedCreditsPerCountryError()))
      )
    )
  );

export const fetchCompaniesCountEpic: Epic<
  | ActionType<typeof fetchCompaniesCountSuccess>
  | ActionType<typeof fetchCompaniesCountError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_COMPANIES_COUNT)),
    switchMap(({ payload }) =>
      fetchCounts(request, config, payload).pipe(
        map(({ response }) => fetchCompaniesCountSuccess(response)),
        catchError(() => of(fetchCompaniesCountError()))
      )
    )
  );

export const createOrderEpic: Epic<
  ActionType<typeof createOrderSuccess> | ActionType<typeof createOrderError>
> = (action$, _$, { request, config, getAppLanguage }) =>
  action$.pipe(
    filter(isOfType(CREATE_ORDER)),
    exhaustMap(({ payload }) =>
      request({
        method: "POST",
        url: `${config.PROSPECTS_API_URL}/orders`,
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          name: payload.name,
          description: payload.description,
          searchCriteria: mapSearchCriteriaToApiPayload(payload.searchCriteria),
          amountPerCountry: payload.requestedAmounts,
          exportLang: getAppLanguage(),
          previewCount: payload.previewCount,
        },
      }).pipe(
        map(() => createOrderSuccess()),
        catchError((e) =>
          of(
            createOrderError(e && e.response ? e.response.type : "UnknownError")
          )
        )
      )
    )
  );

export const fetchOrderProspectInfoEpic: Epic<
  | ActionType<typeof fetchOrderProspectInfoSuccess>
  | ActionType<typeof fetchOrderProspectInfoError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_ORDER_PROSPECT_INFO)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/orders/${payload.orderId}/prospects/${payload.csCompanyId}`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }).pipe(
        map(({ response }) => fetchOrderProspectInfoSuccess(response)),
        catchError(() => of(fetchOrderProspectInfoError()))
      )
    )
  );

const fetchCounts = (
  request: RequestType,
  config: IConfig,
  payload: FlattenedSearchState
) =>
  request({
    method: "POST",
    url: `${config.PROSPECTS_API_URL}/orders/_preview`,
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    body: {
      searchCriteria: mapSearchCriteriaToApiPayload(payload),
    },
  });

export const downloadProspectListEpic: Epic<
  | ActionType<typeof downloadProspectListSuccess>
  | ActionType<typeof downloadProspectListError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(DOWNLOAD_PROSPECT_LIST)),
    switchMap(({ payload }) => {
      const { orderId, fileFormat } = payload;
      const queryString = fileFormat ? `?fileFormat=${fileFormat}` : "";
      return request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/orders/${orderId}/export${queryString}`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }).pipe(
        map(({ response }) => downloadProspectListSuccess(response)),
        catchError(() => of(downloadProspectListError()))
      );
    })
  );

export const fetchPreviousExcludedCompaniesCountEpic: Epic<
  | ActionType<typeof fetchPreviousExcludedCompaniesCountSuccess>
  | ActionType<typeof fetchPreviousExcludedCompaniesCountError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/orders/_excludedCompanies?excludePreviousOrdersDuration=${payload.excludePreviousOrdersDuration}`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }).pipe(
        map(({ response }) =>
          fetchPreviousExcludedCompaniesCountSuccess(response)
        ),
        catchError(() => of(fetchPreviousExcludedCompaniesCountError()))
      )
    )
  );
