import { Epic } from "../rootEpic";
import { forkJoin, of } from "rxjs";
import { catchError, filter, switchMap } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";
import { DEFAULT_LIMIT, DEFAULT_PAGE } from "../../constants";
import { getTotalCountHeader } from "../utils/getTotalCountHeader";
import {
  createJobError,
  createJobSuccess,
  fetchJobDetailsError,
  fetchJobDetailsSuccess,
  fetchJobs,
  fetchJobSample,
  fetchJobSampleError,
  fetchJobSampleSuccess,
  fetchJobsError,
  fetchJobsSuccess,
  saveJobMappingsError,
  saveJobMappingsSuccess,
  setMappingJob,
  submitJob,
  submitJobError,
  submitJobSuccess,
} from "./actions";
import {
  CREATE_JOB,
  FETCH_JOBS,
  JobSource,
  FETCH_JOB_DETAILS,
  FETCH_JOB_SAMPLE,
  FETCH_JOB_DETAILS_SUCCESS,
  SAVE_JOB_MAPPINGS,
  SAVE_JOB_MAPPINGS_SUCCESS,
  SUBMIT_JOB,
} from "./types";

export const fetchJobsEpic: Epic<
  ActionType<typeof fetchJobsSuccess> | ActionType<typeof fetchJobsError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_JOBS)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.DATA_CLEANING_API_URL}/jobs?_page=${payload.page}&_limit=${payload.limit}&source=${JobSource.prospects}&_sort=CreatedAt`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }).pipe(
        switchMap((response) => {
          const count = getTotalCountHeader(response);
          const results = response.response;
          return of(
            fetchJobsSuccess({
              jobs: results,
              total: parseInt(count, 10),
            })
          );
        }),
        catchError(() => of(fetchJobsError()))
      )
    )
  );

export const createJobEpic: Epic<
  | ActionType<typeof createJobSuccess>
  | ActionType<typeof createJobError>
  | ActionType<typeof fetchJobs>
  | ActionType<typeof setMappingJob>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(CREATE_JOB)),
    switchMap(({ payload }) => {
      return request({
        method: "POST",
        url: `${config.DATA_CLEANING_API_URL}/jobs`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {
          name: payload.jobDetails.name,
          source: payload.jobDetails.source,
        },
      }).pipe(
        switchMap((job) =>
          request({
            method: "POST",
            url: `${config.DATA_CLEANING_API_URL}/jobs/${job.response.id}/contacts`,
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
            body: {
              person: payload.jobDetails.person,
              telephone: payload.jobDetails.telephone,
              email: payload.jobDetails.email,
            },
          }).pipe(
            switchMap(() => {
              const body = new FormData();
              body.append("jobFile", payload.jobDetails.jobFile);
              body.append("hasHeader", String(payload.jobDetails.hasHeader));
              return request({
                method: "POST",
                url: `${config.DATA_CLEANING_API_URL}/jobs/${job.response.id}/_upload`,
                headers: {},
                body,
              }).pipe(
                switchMap(() =>
                  of(
                    createJobSuccess(),
                    setMappingJob(job.response),
                    fetchJobs({
                      page: DEFAULT_PAGE + 1,
                      limit: DEFAULT_LIMIT,
                    })
                  )
                )
              );
            })
          )
        ),
        catchError((e) =>
          of(createJobError(e && e.response ? e.response.type : "UnknownError"))
        )
      );
    })
  );

export const fetchJobByIdEpic: Epic<
  | ActionType<typeof fetchJobDetailsSuccess>
  | ActionType<typeof fetchJobDetailsError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_JOB_DETAILS)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.jobId}`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }).pipe(
        switchMap(({ response }) => of(fetchJobDetailsSuccess(response))),
        catchError(() => of(fetchJobDetailsError()))
      )
    )
  );

export const conditionallyFetchSample: Epic<ActionType<typeof fetchJobSample>> =
  (action$) =>
    action$.pipe(
      filter(isOfType(FETCH_JOB_DETAILS_SUCCESS)),
      filter(({ payload }) => payload.status === "uploaded"),
      switchMap(({ payload }) =>
        of(
          fetchJobSample({
            jobId: payload.id,
            page: 1,
            limit: 26,
          })
        )
      )
    );

export const fetchJobSampleEpic: Epic<
  | ActionType<typeof fetchJobSampleSuccess>
  | ActionType<typeof fetchJobSampleError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_JOB_SAMPLE)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.jobId}/files?active=true`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }).pipe(
        switchMap((files) =>
          request({
            method: "GET",
            url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.jobId}/files/${files.response[0].id}/_sample?_page=${payload.page}&_limit=${payload.limit}`,
            headers: {
              accept: "application/json",
              "Content-Type": "application/json",
            },
          }).pipe(
            switchMap(({ response }) =>
              of(
                fetchJobSampleSuccess({
                  sample: response,
                  fileDetails: files.response[0],
                })
              )
            )
          )
        ),
        catchError(() => of(fetchJobSampleError()))
      )
    )
  );

export const saveJobMappingsEpic: Epic<
  | ActionType<typeof saveJobMappingsSuccess>
  | ActionType<typeof saveJobMappingsError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(SAVE_JOB_MAPPINGS)),
    switchMap(({ payload }) => {
      const requests = [
        request({
          method: "PUT",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.jobId}/mappings`,
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: { mappings: payload.mappings },
        }),
        request({
          method: "PATCH",
          url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.jobId}/files/${payload.fileId}`,
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
          body: { hasHeader: payload.hasHeader },
        }),
      ];
      return forkJoin(requests).pipe(
        switchMap(() => of(saveJobMappingsSuccess({ jobId: payload.jobId }))),
        catchError(() => of(saveJobMappingsError()))
      );
    })
  );

export const conditionallySubmitJob: Epic<ActionType<typeof submitJob>> = (
  action$
) =>
  action$.pipe(
    filter(isOfType(SAVE_JOB_MAPPINGS_SUCCESS)),
    switchMap(({ payload }) => of(submitJob(payload)))
  );

export const submitJobEpic: Epic<
  | ActionType<typeof submitJobSuccess>
  | ActionType<typeof submitJobError>
  | ActionType<typeof fetchJobs>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(SUBMIT_JOB)),
    switchMap(({ payload }) =>
      request({
        method: "POST",
        url: `${config.DATA_CLEANING_API_URL}/jobs/${payload.jobId}/_submit`,
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      }).pipe(
        switchMap(() =>
          of(
            submitJobSuccess(payload),
            fetchJobs({
              page: DEFAULT_PAGE + 1,
              limit: DEFAULT_LIMIT,
            })
          )
        ),
        catchError(() => of(submitJobError()))
      )
    )
  );
