import querystring from "query-string";
import { Epic } from "../rootEpic";
import { filter, switchMap, map, catchError } from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";
import { fetchPortfoliosSuccess } from "./actions";
import { FETCH_PORTFOLIOS_BY_ID, FETCH_PORTFOLIOS } from "./types";
import { EMPTY, forkJoin } from "rxjs";

export const fetchPortfoliosEpic: Epic<
  ActionType<typeof fetchPortfoliosSuccess>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_PORTFOLIOS)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${
          config.PORTFOLIOS_API_URL
        }/portfolios/customers?${querystring.stringify(payload)}`,
      }).pipe(
        map(({ response }) =>
          fetchPortfoliosSuccess({
            portfolios: response.items,
            count: response.count,
          })
        ),
        catchError(() => EMPTY)
      )
    )
  );

export const fetchPortfolioEpic: Epic<
  ActionType<typeof fetchPortfoliosSuccess>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_PORTFOLIOS_BY_ID)),
    switchMap(({ payload }) =>
      forkJoin(
        payload.map((portfolioId) =>
          request({
            method: "GET",
            url: `${config.PORTFOLIOS_API_URL}/portfolios/${portfolioId}`,
          })
        )
      ).pipe(
        map((responses) =>
          fetchPortfoliosSuccess({
            portfolios: responses.map((x) => x.response),
            count: responses.length,
          })
        ),
        catchError(() => EMPTY)
      )
    )
  );
