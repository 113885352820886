import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginTop: theme.spacing(5),
    },
  })
);

const LoadingSpinner: React.FC = ({ children = "Loading..." }) => {
  const classes = useStyles();

  return (
    <Grid
      data-testid="loading-spinner"
      container
      justify="center"
      alignItems="center"
      direction="column"
      spacing={2}
      className={classes.root}
    >
      <Grid item>
        <Typography variant="subtitle1"> {children} </Typography>
      </Grid>
      <Grid item>
        <CircularProgress size={60} />
      </Grid>
    </Grid>
  );
};

export default LoadingSpinner;
