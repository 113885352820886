import { action } from "typesafe-actions";
import {
  CLEAR_ORDERS,
  CreateOrderErrors,
  CreateOrderPayload,
  CREATE_ORDER,
  CREATE_ORDER_ERROR,
  CREATE_ORDER_SUCCESS,
  DownloadProspectPayload,
  DOWNLOAD_PROSPECT_LIST,
  DOWNLOAD_PROSPECT_LIST_ERROR,
  DOWNLOAD_PROSPECT_LIST_SUCCESS,
  FetchOrderByIdPayload,
  FetchOrderProspectPayload,
  FetchOrderProspectsPayload,
  FetchOrdersPayload,
  FETCH_AMOUNT_PER_COUNTRY,
  FETCH_AMOUNT_PER_COUNTRY_ERROR,
  FETCH_AMOUNT_PER_COUNTRY_SUCCESS,
  FETCH_COMPANIES_COUNT,
  FETCH_COMPANIES_COUNT_ERROR,
  FETCH_COMPANIES_COUNT_SUCCESS,
  FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT,
  FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT_SUCCESS,
  FETCH_ORDERS,
  FETCH_ORDERS_ERROR,
  FETCH_ORDERS_SUCCESS,
  FETCH_ORDER_INFO,
  FETCH_ORDER_INFO_ERROR,
  FETCH_ORDER_INFO_SUCCESS,
  FETCH_ORDER_PROSPECTS,
  FETCH_ORDER_PROSPECTS_ERROR,
  FETCH_ORDER_PROSPECTS_SUCCESS,
  FETCH_ORDER_PROSPECT_INFO,
  FETCH_ORDER_PROSPECT_INFO_ERROR,
  FETCH_ORDER_PROSPECT_INFO_SUCCESS,
  OrderInfoResponse,
  OrderProspectInfo,
  OrderProspectsResponse,
  OrdersResponse,
  FetchExcludedCompaniesCountPayload,
  FetchExcludedCompaniesCountResponse,
  PreviewOrderPayload,
  PreviewOrderResponse,
  RESET_PROSPECT_LIST_FILE_PATH,
  FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT_ERROR,
  FETCH_NONLTD_CREDITS_PER_COUNTRY,
  FETCH_NONLTD_CREDITS_PER_COUNTRY_SUCCESS,
  FETCH_NONLTD_CREDITS_PER_COUNTRY_ERROR,
  FetchNonLtdUsedCreditsResponse,
} from "./types";

export const fetchCompaniesCount = (payload: PreviewOrderPayload) =>
  action(FETCH_COMPANIES_COUNT, payload);

export const fetchCompaniesCountSuccess = (payload: PreviewOrderResponse) =>
  action(FETCH_COMPANIES_COUNT_SUCCESS, payload);

export const fetchCompaniesCountError = () =>
  action(FETCH_COMPANIES_COUNT_ERROR);

export const fetchOrders = (payload: FetchOrdersPayload) =>
  action(FETCH_ORDERS, payload);

export const fetchOrdersSuccess = (payload: OrdersResponse) =>
  action(FETCH_ORDERS_SUCCESS, payload);

export const fetchOrdersError = () => action(FETCH_ORDERS_ERROR);

export const clearOrders = () => action(CLEAR_ORDERS);

export const createOrder = (payload: CreateOrderPayload) =>
  action(CREATE_ORDER, payload);

export const createOrderSuccess = () => action(CREATE_ORDER_SUCCESS);

export const createOrderError = (payload: CreateOrderErrors) =>
  action(CREATE_ORDER_ERROR, payload);

export const fetchAmountPerCountry = (payload: PreviewOrderPayload) =>
  action(FETCH_AMOUNT_PER_COUNTRY, payload);

export const fetchAmountPerCountrySuccess = (payload: PreviewOrderResponse) =>
  action(FETCH_AMOUNT_PER_COUNTRY_SUCCESS, payload);

export const fetchAmountPerCountryError = () =>
  action(FETCH_AMOUNT_PER_COUNTRY_ERROR);

export const fetchOrderInfo = (payload: FetchOrderByIdPayload) =>
  action(FETCH_ORDER_INFO, payload);

export const fetchOrderInfoSuccess = (payload: OrderInfoResponse) =>
  action(FETCH_ORDER_INFO_SUCCESS, payload);

export const fetchOrderInfoError = () => action(FETCH_ORDER_INFO_ERROR);

export const fetchOrderProspects = (payload: FetchOrderProspectsPayload) =>
  action(FETCH_ORDER_PROSPECTS, payload);

export const fetchOrderProspectsSuccess = (payload: OrderProspectsResponse) => {
  return action(FETCH_ORDER_PROSPECTS_SUCCESS, payload);
};

export const fetchOrderProspectsError = () =>
  action(FETCH_ORDER_PROSPECTS_ERROR);

export const fetchOrderProspectInfo = (payload: FetchOrderProspectPayload) =>
  action(FETCH_ORDER_PROSPECT_INFO, payload);

export const fetchOrderProspectInfoSuccess = (payload: OrderProspectInfo) =>
  action(FETCH_ORDER_PROSPECT_INFO_SUCCESS, payload);

export const fetchOrderProspectInfoError = () =>
  action(FETCH_ORDER_PROSPECT_INFO_ERROR);

export const downloadProspectList = (payload: FetchOrderByIdPayload) =>
  action(DOWNLOAD_PROSPECT_LIST, payload);

export const downloadProspectListSuccess = (payload: DownloadProspectPayload) =>
  action(DOWNLOAD_PROSPECT_LIST_SUCCESS, payload);

export const downloadProspectListError = () =>
  action(DOWNLOAD_PROSPECT_LIST_ERROR);

export const resetProspectListFilePath = () =>
  action(RESET_PROSPECT_LIST_FILE_PATH);

export const fetchPreviousExcludedCompaniesCount = (
  payload: FetchExcludedCompaniesCountPayload
) => action(FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT, payload);

export const fetchPreviousExcludedCompaniesCountSuccess = (
  payload: FetchExcludedCompaniesCountResponse
) => action(FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT_SUCCESS, payload);

export const fetchPreviousExcludedCompaniesCountError = () =>
  action(FETCH_PREVIOUS_COMPANIES_EXCLUDED_COUNT_ERROR);

export const fetchNonLtdUsedCreditsPerCountry = (payload: string) =>
  action(FETCH_NONLTD_CREDITS_PER_COUNTRY, payload);

export const fetchNonLtdUsedCreditsPerCountrySuccess = (
  payload: FetchNonLtdUsedCreditsResponse
) => action(FETCH_NONLTD_CREDITS_PER_COUNTRY_SUCCESS, payload);

export const fetchNonLtdUsedCreditsPerCountryError = () =>
  action(FETCH_NONLTD_CREDITS_PER_COUNTRY_ERROR);
