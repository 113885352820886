import enGB from "numbro/languages/en-GB";
import frFR from "numbro/languages/fr-FR";
import deDE from "numbro/languages/de-DE";
import nlBE from "numbro/languages/nl-BE";

export const localeConfig = [
  {
    name: "en-US",
  },
  {
    name: "en-GB",
    numbroLanguage: enGB,
    datePickerFormat: "d/M/yyyy",
  },
  {
    name: "en-CA",
    numbroLanguage: enGB,
  },
  {
    name: "fr-FR",
    numbroLanguage: frFR,
    overrides: [{ path: "delimiters.thousands", pathValue: "." }],
  },
  {
    name: "fr-BE",
    numbroLanguage: frFR,
    overrides: [
      { path: "delimiters.thousands", pathValue: "." },
      { path: "languageTag", pathValue: "fr-BE" },
    ],
    momentLocaleParent: "fr",
  },
  {
    name: "de-DE",
    numbroLanguage: deDE,
    overrides: [{ path: "delimiters.thousands", pathValue: "." }],
  },
  {
    name: "nl-BE",
    numbroLanguage: nlBE,
    overrides: [{ path: "delimiters.thousands", pathValue: "." }],
  },
];
