import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { SplitTreatments } from "@splitsoftware/splitio-react";
import { fireViewStartCustomEvent } from "../../analyticsHelpers";
import AppBar from "../../components/AppBar";
import { fetchAppVersion } from "../../store/app/actions";
import { getRequiresAppUpdate } from "../../store/app/selectors";
import {
  getShouldUseUpp,
  getUserCountry,
  getUserDetails,
} from "../../store/user/selectors";
import Routes from "../Routes/Routes";
import MessageModal from "../../components/Shared/MessageModal";
import { SiteUnavailable } from "@creditsafe/portfolios-shared-components";
import siteUnavailableImage from "../../assets/under-construction.png";
import config from "../../config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    drawer: {
      flexShrink: 0,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    routes: {
      color: theme.palette.grey[800],
    },
  })
);

const featureName = "Maintenance_Prospects";

const AppLayout = () => {
  const classes = useStyles();
  const { t } = useTranslation("global");
  const [mobileOpen, setMobileOpen] = useState(false);
  const requiresAppUpdate = useSelector(getRequiresAppUpdate);
  const isUppUser = useSelector(getShouldUseUpp);
  const userCountry = useSelector(getUserCountry);
  const userDetails = useSelector(getUserDetails);

  useEffect(() => {
    const freshWorkTag = document.getElementById("freshworks-container");
    if (freshWorkTag) {
      if (["UK", "GB", "IE"].includes(userDetails?.countryCode || "")) {
        freshWorkTag.style.display = "block";
      } else freshWorkTag.style.display = "none";
    }
  }, [userDetails]);

  const siteUnavailableAction = isUppUser
    ? config.UPP_APP_URL
    : `${config.LINKAGES_ROUTER_URL}?DepartureCountry=${userCountry}&DestinationCountry=${userCountry}&HopType=CompanySearch`;
  const dispatch = useDispatch();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const location = useLocation();

  useEffect(() => {
    fireViewStartCustomEvent({ detail: { mbox: "target-global-mbox" } });
  }, [location.pathname]);

  useEffect(() => {
    if (window.environment.name !== "local") {
      dispatch(fetchAppVersion());
    }
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <AppBar onToggle={handleDrawerToggle} />
      <main className={classes.content}>
        <Toolbar />
        <SplitTreatments names={[featureName]}>
          {({ treatments, isReady }) => {
            if (isReady && treatments[featureName].treatment === "on") {
              return (
                <SiteUnavailable
                  title={t("maintenance.title")}
                  description={t("maintenance.description")}
                  button={t("maintenance.button")}
                  action={siteUnavailableAction}
                  image={siteUnavailableImage}
                />
              );
            }
            return <Routes />;
          }}
        </SplitTreatments>
        <MessageModal
          title={t("appUpdateTitle")}
          message={t("appUpdateDesc")}
          isOpen={requiresAppUpdate}
          handleClose={() => window.location.reload()}
          hideCloseIcon={true}
          closeButtonLabel={t("refresh")}
        />
      </main>
    </div>
  );
};

export default AppLayout;
