import React from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Cslogo } from "../../assets/cslogo.svg";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "90vh",
    },
  })
);

const SignInLoading: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation("global");

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      direction="column"
      spacing={2}
      className={classes.root}
    >
      <Grid item>
        <Cslogo width="198px" height="50px" />
      </Grid>
      <Grid item>
        <Typography variant="subtitle1">{t("signingYouIn")}...</Typography>
      </Grid>
      <Grid item>
        <CircularProgress size={60} />
      </Grid>
    </Grid>
  );
};

export default SignInLoading;
