import { action } from "typesafe-actions";
import {
  FetchUserCreditsResponse,
  FETCH_ADMIN_USER,
  FETCH_ADMIN_USER_ERROR,
  FETCH_ADMIN_USER_SUCCESS,
  FETCH_USER_CREDITS,
  FETCH_USER_CREDITS_ERROR,
  FETCH_USER_CREDITS_SUCCESS,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_ERROR,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_ENTITY_DETAILS,
  FETCH_USER_ENTITY_DETAILS_ERROR,
  FETCH_USER_ENTITY_DETAILS_SUCCESS,
  PUT_USER_LANG,
  PUT_USER_LANG_ERROR,
  PUT_USER_LANG_SUCCESS,
  UserDetailsPayload,
  UserEntityDetailsPayload,
  UserSignedInPayload,
  USER_SIGNED_IN,
} from "./types";

export const userSignedIn = (payload: UserSignedInPayload) =>
  action(USER_SIGNED_IN, payload);

// User Details Specfic Actions
export const fetchUserDetailsAction = (payload: string) =>
  action(FETCH_USER_DETAILS, payload);

export const fetchUserDetailsSuccessAction = (payload: UserDetailsPayload) =>
  action(FETCH_USER_DETAILS_SUCCESS, payload);

export const fetchUserDetailsErrorAction = () =>
  action(FETCH_USER_DETAILS_ERROR);

//admin user
export const fetchAdminUserAction = () => action(FETCH_ADMIN_USER);

export const fetchAdminUserSuccessAction = (payload: boolean) =>
  action(FETCH_ADMIN_USER_SUCCESS, payload);

export const fetchAdminUserErrorAction = () => action(FETCH_ADMIN_USER_ERROR);

// User Entity Specfic Actions
export const fetchUserEntityDetailsAction = (payload: string) =>
  action(FETCH_USER_ENTITY_DETAILS, payload);

export const fetchUserEntityDetailsSuccessAction = (
  payload: UserEntityDetailsPayload
) => action(FETCH_USER_ENTITY_DETAILS_SUCCESS, payload);

export const fetchUserEntityDetailsErrorAction = () =>
  action(FETCH_USER_ENTITY_DETAILS_ERROR);

// User Save Lang
export const putUserLangAction = (payload: string) =>
  action(PUT_USER_LANG, payload);

export const putUserLangSuccessAction = () => action(PUT_USER_LANG_SUCCESS);

export const putUserLangErrorAction = () => action(PUT_USER_LANG_ERROR);

export const fetchUserCredits = () => action(FETCH_USER_CREDITS);

export const fetchUserCreditsSuccess = (payload: FetchUserCreditsResponse) =>
  action(FETCH_USER_CREDITS_SUCCESS, payload);

export const fetchUserCreditsError = () => action(FETCH_USER_CREDITS_ERROR);
