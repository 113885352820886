import {
  FETCH_ADMIN_USER_SUCCESS,
  FETCH_USER_CREDITS,
  FETCH_USER_CREDITS_ERROR,
  FETCH_USER_CREDITS_SUCCESS,
  FETCH_USER_DETAILS_SUCCESS,
  FETCH_USER_ENTITY_DETAILS_SUCCESS,
  UserActionTypes,
  UserState,
  USER_SIGNED_IN,
} from "./types";

export const initialState: UserState = {
  userId: null,
  isSignedIn: false,
  isLoadingUserCredits: false,
  remainingCredits: 0,
};

export default function (
  state = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case USER_SIGNED_IN: {
      return {
        ...state,
        userId: action.payload,
        isSignedIn: true,
      };
    }
    case FETCH_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        userDetails: { ...state.userDetails, ...action.payload },
      };
    }
    case FETCH_ADMIN_USER_SUCCESS: {
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isAdminUserAllowed: action.payload,
        },
      };
    }
    case FETCH_USER_ENTITY_DETAILS_SUCCESS: {
      return {
        ...state,
        entityInfo: { ...action.payload },
      };
    }
    case FETCH_USER_CREDITS: {
      return {
        ...state,
        isLoadingUserCredits: true,
      };
    }
    case FETCH_USER_CREDITS_SUCCESS: {
      return {
        ...state,
        isLoadingUserCredits: false,
        remainingCredits: action.payload.remainingLimit,
      };
    }
    case FETCH_USER_CREDITS_ERROR: {
      return {
        ...state,
        isLoadingUserCredits: false,
        remainingCredits: 0,
      };
    }
    default:
      return state;
  }
}
