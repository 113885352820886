import deLocale from "date-fns/locale/de";
import enCALocale from "date-fns/locale/en-CA";
import enGBLocale from "date-fns/locale/en-GB";
import enUSLocale from "date-fns/locale/en-US";
import frLocale from "date-fns/locale/fr";
import frCALocale from "date-fns/locale/fr-CA";
import itLocale from "date-fns/locale/it";
import jaLocale from "date-fns/locale/ja";
import nbLocale from "date-fns/locale/nb";
import nlLocale from "date-fns/locale/nl";
import daLocale from "date-fns/locale/da";
import svLocale from "date-fns/locale/sv";
import { TFunction } from "i18next";
import { capitalize, get, pick, range, some, uniq } from "lodash";
import moment from "moment";
import numbro from "numbro";
import { FEATURE_FLAGS, isFeatureAvailable } from "./config/featureFlag";
import i18n, { locales } from "./config/i18n-client";
import {
  ActivityCode,
  FetchLocationsResponse,
  Location,
} from "./store/criteria/types";
import { OrderProspectInfo } from "./store/order/types";
import { SearchCategories, SearchState } from "./store/search/types";
import {
  AllSearchFilterKeys,
  companyFinancialFields,
} from "./utils/searchFilters";
import { GEOGRAPHIC_SEPERATOR } from "./constants";

/*******************************
 ***** APPLICATION MODELS ***
 *******************************/
export interface ParsedLocation {
  country: string;
  level: number;
  parentId: string;
  name: string;
  key: string;
  subItems?: ParsedLocation[];
}

export interface LocationGroup {
  key: string;
  countries: Location[];
}

export interface ParsedActivityCode {
  key: string;
  parentId: string;
  subItems?: ParsedActivityCode[];
}

export enum FileFormats {
  csv = "csv",
  xlsx = "xlsx",
}

/*******************************
 ***** APPLICATION UTILS ***
 *******************************/
export const noop = () => {
  // Do nothing
};

export function identity<T>(x: T) {
  return x;
}

export function isObject<T>(x: T) {
  return x !== null && typeof x === "object";
}

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const unitIndex = Math.floor(Math.log(bytes) / Math.log(k));
  const appliedUnitIndex =
    unitIndex >= sizes.length ? sizes.length - 1 : unitIndex;

  return (
    parseFloat((bytes / Math.pow(k, appliedUnitIndex)).toFixed(dm)) +
    " " +
    sizes[appliedUnitIndex]
  );
};

export const slugify = (strValue: string) => {
  const a =
    "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
  const b =
    "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------";
  const p = new RegExp(a.split("").join("|"), "g");

  return strValue == null || strValue == undefined
    ? ""
    : strValue
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, ""); // Trim - from end of text
};

export const percentage = (x: number, digits: number) =>
  !isNaN(x) && isFinite(x)
    ? `${digits ? Math.abs(x).toFixed(digits) : x}%`
    : "";
export const formatDate = (date: Date) => moment(date).format("YYYY-MM-DD");

export const formatNumber = (value: number | undefined): string =>
  value != undefined && !isNaN(value)
    ? numbro(value).format({
        thousandSeparated: true,
      })
    : "";

export const formatDecimal = (value: string | number | undefined) => {
  if (value) {
    const re = /^\d+\.?\d{0,1}$/;
    value = value.toString();
    if (re.test(value)) {
      if (value.endsWith(".")) {
        return parseFloat(value) + ".";
      } else if (value.endsWith(".0")) {
        return value;
      }
    }
    return re.test(value) ? parseFloat(value) : "";
  } else {
    return "";
  }
};

export const concatObjectFields = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>,
  fields: string[]
) =>
  Object.values(pick(data, fields))
    .filter((f) => f && f.length > 0)
    .join(" ");

export const setAppLanguage = (language: string) => {
  if (i18n.language !== language && some(locales, { code: language })) {
    i18n.changeLanguage(language);
    numbro.setLanguage(language);
  }
};

export const getAppLanguage = (): string => {
  return i18n.language || "en-GB";
};

export const getAppLocale = (language: string) => {
  const locales: { [key: string]: Locale } = {
    "en-US": enUSLocale,
    "en-GB": enGBLocale,
    "fr-BE": frLocale,
    "nl-BE": nlLocale,
    "de-DE": deLocale,
    "en-CA": enCALocale,
    "nl-NL": nlLocale,
    "nb-NO": nbLocale,
    "fr-CA": frCALocale,
    "fr-FR": frLocale,
    "ja-JP": jaLocale,
    "en-IE": enGBLocale,
    "it-IT": itLocale,
    "da-DK": daLocale,
    "sv-SE": svLocale,
  };

  return locales[language] || enUSLocale;
};

export const getDateFormat = (language: string) => {
  const Dates: { [key: string]: string } = {
    "en-US": "MM/dd/yyyy",
    "en-GB": "dd/MM/yyyy",
    "fr-BE": "dd/MM/yyyy",
    "nl-BE": "dd/MM/yyyy",
    "de-DE": "dd.MM.yyyy",
    "en-CA": "dd/MM/yyyy",
    "nl-NL": "dd-MM-yyyy",
    "nb-NO": "dd.MM.yyyy",
    "fr-CA": "yyyy-MM-dd",
    "fr-FR": "dd/MM/yyyy",
    "ja-JP": "yyyy/MM/dd",
    "en-IE": "dd/MM/yyyy",
    "it-IT": "dd/MM/yyyy",
    "da-DK": "dd-MM-yyyy",
  };

  return Dates[language] || "dd/mm/yyyy";
};

export const formatDateWithlocale = (date: Date) => {
  return moment(date).format(getDateFormat(getAppLanguage()).toUpperCase());
};

export const validateDateFormat = (date: Date) => {
  return moment(
    date,
    getDateFormat(getAppLanguage()).toUpperCase(),
    true
  ).isValid();
};

export const convertStringToDateNull = (dateString?: string): Date | null =>
  dateString ? moment(dateString).toDate() : null;

export const downloadFile = (fileUrl: string) => {
  window.open(fileUrl, "_blank");
};

export const getCurrencies = (
  t: TFunction
): { label: string; value: string }[] => {
  return [
    {
      label: t("currencies.DKK"),
      value: "DKK",
    },
    {
      label: t("currencies.EUR"),
      value: "EUR",
    },
    {
      label: t("currencies.NOK"),
      value: "NOK",
    },
    {
      label: t("currencies.GBP"),
      value: "GBP",
    },
    {
      label: t("currencies.SEK"),
      value: "SEK",
    },
    {
      label: t("currencies.USD"),
      value: "USD",
    },
  ];
};

export const getDropdownCountries = (t: TFunction) => {
  return {
    AT: t("geographic:countries.AT"),
    BE: t("geographic:countries.BE"),
    CA: t("geographic:countries.CA"),
    DE: t("geographic:countries.DE"),
    DK: t("geographic:countries.DK"),
    FR: t("geographic:countries.FR"),
    GB: t("geographic:countries.GB"),
    IE: t("geographic:countries.IE"),
    IT: t("geographic:countries.IT"),
    /* JP: t("geographic:countries.JP"), */
    LU: t("geographic:countries.LU"),
    NL: t("geographic:countries.NL"),
    NO: t("geographic:countries.NO"),
    SE: t("geographic:countries.SE"),
    US: t("geographic:countries.US"),
  };
};

export const nonLimitedAnualCapCountries = ["GB", "IE"];

export const getGeographies = (
  userCountry: string | undefined
): LocationGroup[] => {
  const europeCountries = [
    "BE",
    "DK",
    "FR",
    "DE",
    "IE",
    "IT",
    "LU",
    "NL",
    "NO",
    "SE",
    "GB",
  ];

  const northAmericaCountries = ["US", "CA"];

  const continents = [
    { name: "europe", countries: europeCountries },
    { name: "northAmerica", countries: northAmericaCountries },
  ];

  const restrictCADataAccess =
    userCountry !== "CA" ? (c: string) => c !== "CA" : identity;

  return continents.map((continent) => ({
    key: continent.name,
    countries: continent.countries
      .filter((country) => isFeatureAvailable(FEATURE_FLAGS.countries, country))
      .filter(restrictCADataAccess)
      .map((country) => ({
        key: country,
      })),
  }));
};

export const getGeographyDepth = (country: string): number => {
  const levels = {
    BE: 3,
    DK: 3,
    FR: 3,
    DE: 4,
    IE: 2,
    IT: 3,
    LU: 4,
    NL: 3,
    NO: 3,
    SE: 3,
    GB: 4,
    US: 3,
    CA: 1,
  };
  return get(levels, country, 0);
};

export const parseLocationItemKey = (key: string): ParsedLocation => {
  const splittedKey = key.split(GEOGRAPHIC_SEPERATOR);
  const level = splittedKey.length;
  const country = splittedKey[0];
  const name = splittedKey[splittedKey.length - 1];
  const parentId = splittedKey
    .slice(0, splittedKey.length - 1)
    .join(GEOGRAPHIC_SEPERATOR);

  return {
    country,
    level,
    parentId,
    name,
    key,
  };
};

export const createLocationNestedList = (
  items: ParsedLocation[],
  key: string
): ParsedLocation[] =>
  items
    .filter((x) => x.parentId === key)
    .map((x) => ({ ...x, subItems: createLocationNestedList(items, x.key) }));

export const getLocationItemName = (key: string, t: TFunction): string => {
  const parsedLocation = parseLocationItemKey(key);

  if (parsedLocation.level === 1) {
    return t(`geographic:countries.${parsedLocation.key}`);
  } else if (parsedLocation.level === 2 && parsedLocation.country === "GB") {
    return t(`geographic:subCountries.${parsedLocation.name}`);
  }

  return capitalize(parsedLocation.name);
};

export const getActivityCodeLevel = (activityCode: ActivityCode): number => {
  const splittedPath = activityCode.parentPath.split("/");
  return activityCode.parentPath === "-" ? 1 : splittedPath.length + 1;
};

export const getActivityCodeFullKey = (activityCode: ActivityCode): string =>
  activityCode.parentPath === "-"
    ? activityCode.code
    : `${activityCode.parentPath}/${activityCode.code}`;

export const getActivityCodeFromFullKey = (key: string): string => {
  const splittedKey = key.split("/");
  return splittedKey[splittedKey.length - 1];
};

export const createActivityCodesNestedList = (
  items: ParsedActivityCode[],
  key: string
): ParsedActivityCode[] =>
  items
    .filter((x) => x.parentId === key)
    .map((x) => ({
      ...x,
      subItems: createActivityCodesNestedList(items, x.key),
    }));

export const parseActivityCodeItemKey = (key: string): ParsedActivityCode => {
  const splittedKey = key.split("/");
  const parentId = splittedKey.slice(0, splittedKey.length - 1).join("/");

  return {
    key,
    parentId,
  };
};

export const expandHierarchyKey = (pathKey: string): string[] =>
  pathKey
    .split(GEOGRAPHIC_SEPERATOR)
    .map((_, i, a) => a.slice(0, i + 1).join(GEOGRAPHIC_SEPERATOR));

/*******************************
 ***** SEARCH CRITERIA UTILS ***
 *******************************/
type ExcludedFieldRules = {
  [key: string]: Partial<{ [key in SearchCategories]: AllSearchFilterKeys[] }>;
};

const excludedFieldsRules: ExcludedFieldRules = {
  BE: {
    companyFinancials: ["netWorth", "workingCapital", "pretaxProfitMargin"],
    companyProfile: ["dbt", "registeredTypes", "addressTypes"],
    exclusions: ["marketingAllowed"],
  },
  DE: {
    exclusions: ["marketingAllowed"],
    companyProfile: ["dbt", "addressTypes"],
  },
  DK: {
    companyProfile: ["dbt", "registeredTypes", "addressTypes"],
    businessSignals: ["creditScoreTrend"],
  },
  FR: {
    companyFinancials: [
      "netWorth",
      "currentRatio",
      "pretaxProfitMargin",
      "returnCapitalEmployed",
      "currentDebtRatio",
      "totalDebtRatio",
    ],
    companyProfile: ["dbt"],
    exclusions: ["marketingAllowed"],
  },
  GB: { companyProfile: ["dbt", "addressTypes"] },
  IE: { companyProfile: ["dbt", "addressTypes"] },
  IT: {
    companyProfile: ["dbt", "addressTypes"],
    exclusions: ["marketingAllowed"],
  },
  LU: {
    exclusions: ["marketingAllowed"],
    companyProfile: ["dbt", "addressTypes", "registeredTypes"],
  },
  NL: {
    companyFinancials: ["netWorth", "workingCapital"],
    companyProfile: ["dbt", "registeredTypes"],
  },
  NO: {
    companyProfile: ["dbt"],
    exclusions: ["marketingAllowed"],
  },
  SE: {
    companyProfile: ["dbt", "addressTypes"],
    businessSignals: [
      "creditLimitMin",
      "creditLimitMax",
      "creditScoreIncludeNotFiled",
      "creditScoreMin",
      "creditScoreMax",
      "creditScoreTrendsIncrease",
    ],
  },
  US: {
    companyFinancials: [
      "pretaxProfit",
      "profit",
      "totalFixedAssets",
      "totalCurrentAssets",
      "totalCurrentLiabilities",
      "totalLongTermLiabilities",
      "shareholderFunds",
      "netWorth",
      "workingCapital",
      "currentRatio",
      "pretaxProfitMargin",
      "returnCapitalEmployed",
      "totalDebtRatio",
      "currentDebtRatio",
      "shareCapital",
    ],
    companyProfile: ["registeredTypes"],
    exclusions: ["marketingAllowed"],
  },
  CA: {
    companyFinancials: [
      "currency",
      "pretaxProfit",
      "profit",
      "totalFixedAssets",
      "totalCurrentAssets",
      "totalCurrentLiabilities",
      "totalLongTermLiabilities",
      "shareholderFunds",
      "netWorth",
      "workingCapital",
      "currentRatio",
      "pretaxProfitMargin",
      "returnCapitalEmployed",
      "totalDebtRatio",
      "currentDebtRatio",
      "shareCapital",
    ],
    companyProfile: ["dbt", "addressTypes", "registeredTypes"],
    businessSignals: [
      "creditScoreMin",
      "creditScoreMax",
      "creditScoreTrend",
      "creditScoreIncludeNegativeScores",
    ],
    exclusions: ["marketingAllowed", "financialAvailable"],
  },
};

const excludedReportRules = {
  DE: ["businessSignals"],
};

const mapReportFields: { [key: string]: { [key: string]: string } } = {
  companyDetails: {
    companyName: "name",
    companyStatus: "status",
    orgNumber: "orgNumber",
    country: "country",
    safeNumber: "csCompanyId",
    legalForm: "legalForm",
    incorporationDate: "incorpDate",
    employees: "employees",
    activityCode: "activityCode",
    activityDescription: "activityDescription",
    companyType: "companyType",
  },
  businessSignals: {
    creditScore: "creditScore",
    commonScore: "commonScore",
    creditLimit: "creditLimit",
    creditScoreDefinition: "creditScoreDefinition",
  },
  companyContact: {
    address1: "address1",
    address2: "address2",
    address3: "address3",
    address5: "address5",
    region: "region",
    city: "city",
    postcode: "zip",
    phone: "phone",
    marketingAllowed: "marketingAllowed",
    country: "country",
    website: "website",
  },
};

export const excludedCriteriaModal = ["companyProfile.activityType"];

export const getCompanyFinancialsFields = (selectedCountries: string[]) => {
  let fieldsToExclude: string[] = [];

  selectedCountries.forEach((country) => {
    fieldsToExclude = fieldsToExclude.concat(
      getCountryCategoryExcludedFields(country, "companyFinancials")
    );
  });

  return companyFinancialFields.filter(
    (v) => fieldsToExclude.indexOf(v) < 0 && v !== "currency"
  );
};

export const getCountryCategoryExcludedFields = (
  country: string,
  category: SearchCategories
) => excludedFieldsRules[country]?.[category] || [];

export const getCountryExcludedFields = (country: string) =>
  excludedFieldsRules[country] || {};

export const isCriteriaFieldAvailable = (
  category: SearchCategories,
  field: AllSearchFilterKeys,
  countries: string[]
): boolean =>
  !countries.some((country) =>
    (excludedFieldsRules[country]?.[category] || []).includes(field)
  );

export const isReportSectionAvailable = (country: string, section: string) =>
  !get(excludedReportRules, country, []).includes(section);

export const extractReportProspectDetails = (
  prospectData: OrderProspectInfo | null,
  section: string
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: { [key: string]: any } = {};
  const mappings = mapReportFields[section];

  if (prospectData) {
    Object.entries(mappings).forEach(([key, value]) => {
      if (prospectData[value as keyof OrderProspectInfo] !== undefined) {
        result[key] = prospectData[value as keyof OrderProspectInfo];
      }
    });
  }

  return result;
};

export const getRangeOptions = (
  startingValue: number,
  endingValue: number
): { label: number; value: number }[] => {
  const options: { label: number; value: number }[] = [];

  range(startingValue, endingValue + 1).forEach((n) =>
    options.push({
      label: n,
      value: n,
    })
  );
  return options;
};

export const isInvalidBusinessSignalsCriteria = (
  searchCriteria: SearchState
) => {
  const searchCountries = searchCriteria["geographic.locations"] || [];
  const searchRegTypes = searchCriteria["companyProfile.registeredTypes"] || [];
  return (
    (searchCountries.includes("NO") || searchCountries.includes("SE")) &&
    (searchRegTypes.includes("nonLimited") || !searchRegTypes.length)
  );
};

export const isInvalidBusinessClassificationCriteria = (
  searchCriteria: SearchState
) => {
  const locations = searchCriteria["geographic.locations"] || [];
  const searchCountries = uniq(
    locations.map((x) => x.split(GEOGRAPHIC_SEPERATOR)[0])
  );
  const searchRegTypes = searchCriteria["companyProfile.registeredTypes"] || [];
  return (
    searchCountries.length !== 1 ||
    searchRegTypes.length !== 1 ||
    !searchCountries.includes("GB") ||
    !searchRegTypes.includes("nonLimited")
  );
};

export const isInvalidDirectorAgeCriteria = (searchCriteria: SearchState) => {
  const locations = searchCriteria["geographic.locations"] || [];
  const searchCountries = uniq(
    locations.map((x) => x.split(GEOGRAPHIC_SEPERATOR)[0])
  );
  return searchCountries.length !== 1 || !searchCountries.includes("GB");
};

export const isInvalidCompanyAuditorCriteria = (
  searchCriteria: SearchState
) => {
  const locations = searchCriteria["geographic.locations"] || [];
  const searchCountries = uniq(
    locations.map((x) => x.split(GEOGRAPHIC_SEPERATOR)[0])
  );
  return searchCountries.length !== 1 || !searchCountries.includes("GB");
};

export const clamp = (value: number, min: number, max: number) =>
  Math.max(min, Math.min(value, max));

export const stringToNumber = (value: string): number | undefined => {
  const re = /^[0-9\b]+$/;
  return re.test(value) ? parseInt(value) : undefined;
};

export const validateRangeInputs = (
  min?: number,
  max?: number,
  maxRange?: number
): { errorMsg: string; hasError: boolean } => {
  let errorMsg = "noError";
  let hasError = false;
  const maxOrMinValue = maxRange ? maxRange : 999999999999;

  if (
    (min !== undefined && min > maxOrMinValue) ||
    (max !== undefined && max > maxOrMinValue)
  ) {
    errorMsg = "error.invalidMinOrMax_new";
    hasError = true;
  }
  if (min !== undefined && max !== undefined && max < min) {
    errorMsg = "error.maxLessThanMin";
    hasError = true;
  }
  return { errorMsg, hasError };
};

export const validateNumberOnRegExp = (value: string): string => {
  return (value = value.replace(/[\.,\+]/g, ""));
};

export const BusinessClassificationsIsAllowedFor = [
  { Country: "GB", RegisteredType: "nonLimited" },
];

export const CompanyAuditorsIsAllowedFor = ["GB"];

export const CAExportsIsAllowedFor = ["FR"];

export const locationAlphabeticalOrderLanguageSpecific = (
  payload: FetchLocationsResponse,
  language: string
): Location[] => {
  return payload.slice().sort((a, b) => a.key.localeCompare(b.key, language));
};
