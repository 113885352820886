import { action } from "typesafe-actions";
import {
  FetchPortfoliosPayload,
  FetchPortfoliosResponse,
  FETCH_PORTFOLIOS_BY_ID,
  FETCH_PORTFOLIOS,
  FETCH_PORTFOLIOS_SUCCESS,
} from "./types";

export const fetchPortfolios = (payload: FetchPortfoliosPayload) =>
  action(FETCH_PORTFOLIOS, payload);

export const fetchPortfoliosSuccess = (payload: FetchPortfoliosResponse) => {
  return action(FETCH_PORTFOLIOS_SUCCESS, payload);
};

export const fetchPortfoliosById = (payload: string[]) =>
  action(FETCH_PORTFOLIOS_BY_ID, payload);
