import { ActionType } from "typesafe-actions";
import {
  ActivityCode,
  ActivityCodeSearchBody,
  Location,
} from "../criteria/types";
import { DeepNonNullable, KeysMatching } from "../types";
import * as SearchActions from "./actions";

export const SET_SEARCH = "SET_SEARCH";
export const SET_SEARCH_LOCATIONS = "SET_SEARCH_LOCATIONS";
export const DELETE_SEARCH_CRITERIA = "DELETE_SEARCH_CRITERIA";
export const RESET_SEARCH = "RESET_SEARCH";
export const SET_INVALID_FIELDS = "SET_INVALID_FIELDS";
export const UPDATE_SEARCH_CRITERIA_VALUE_ARRAY =
  "UPDATE_SEARCH_CRITERIA_VALUE_ARRAY";
export const UPDATE_SEARCH_CRITERIA_ARRAY = "UPDATE_SEARCH_CRITERIA_ARRAY";
export const SET_SEARCH_INTERNATIONAL_ACTIVITY =
  "SET_SEARCH_INTERNATIONAL_ACTIVITY";
export const SET_SEARCH_LOCAL_ACTIVITY = "SET_SEARCH_LOCAL_ACTIVITY";

export type SearchCategories =
  | "businessSignals"
  | "companyFinancials"
  | "companyProfile"
  | "exclusions"
  | "geographic";

interface BusinessSignals {
  creditLimitIncludeNotFiled?: boolean;
  creditLimitMax?: number;
  creditLimitMin?: number;
  creditScoreIncludeNegativeScores?: boolean;
  creditScoreIncludeNotFiled?: boolean;
  creditScoreMax?: number;
  creditScoreMin?: number;
  creditScoreTrend?: string;
  internationalScores?: string[];
}

interface CompanyFinancials {
  currency?: string;
  currentDebtRatioMax?: number;
  currentDebtRatioMin?: number;
  currentRatioMax?: number;
  currentRatioMin?: number;
  netWorthMax?: number;
  netWorthMin?: number;
  pretaxProfitMarginMax?: number;
  pretaxProfitMarginMin?: number;
  pretaxProfitMax?: number;
  pretaxProfitMin?: number;
  profitMax?: number;
  profitMin?: number;
  returnCapitalEmployedMax?: number;
  returnCapitalEmployedMin?: number;
  shareCapitalMax?: number;
  shareCapitalMin?: number;
  shareholderFundsMax?: number;
  shareholderFundsMin?: number;
  totalCurrentAssetsMax?: number;
  totalCurrentAssetsMin?: number;
  totalCurrentLiabilitiesMax?: number;
  totalCurrentLiabilitiesMin?: number;
  totalDebtRatioMax?: number;
  totalDebtRatioMin?: number;
  totalFixedAssetsMax?: number;
  totalFixedAssetsMin?: number;
  totalLongTermLiabilitiesMax?: number;
  totalLongTermLiabilitiesMin?: number;
  turnoverMax?: number;
  turnoverMin?: number;
  workingCapitalMax?: number;
  workingCapitalMin?: number;
}

interface CompanyProfile {
  activityType?: string;
  addressTypes?: string[];
  companyStatus?: string[];
  dbtMin?: number;
  dbtMax?: number;
  employeesIncludeNotFiled?: boolean;
  employeesMax?: number;
  employeesMin?: number;
  incorporationEndDate?: string;
  incorporationIncludeNotFiled?: boolean;
  incorporationStartDate?: string;
  internationalActivities?: string[];
  excludeInternationalActivities?: string[];
  legalForms?: string[];
  businessClassifications?: string[];
  localActivities?: string[];
  excludeLocalActivities?: string[];
  registeredTypes?: string[];
  directorAgeMax?: number;
  directorAgeMin?: number;
  companyAuditors?: string[];
  caExports?: string[];
}

interface Exclusions {
  excludePortfolios?: string[];
  excludePreviousOrdersDuration?: string;
  financialAvailable?: boolean;
  marketingAllowed?: boolean;
  phoneAvailable?: boolean;
  postcodeAvailable?: boolean;
  uniqueTelephoneNumber?: boolean;
  excludeHasCCJ?: boolean;
}

interface Geographic {
  locations?: string[];
  excludeLocations?: string[];
  postcodes?: string[];
  postCodeSearchType?: string;
}

interface InvalidFields {
  invalidFields?: InvalidFieldsPayload;
}

export type SearchState = {
  [P in keyof BusinessSignals as `businessSignals.${P}`]: BusinessSignals[P];
} &
  {
    [P in keyof CompanyFinancials as `companyFinancials.${P}`]: CompanyFinancials[P];
  } &
  {
    [P in keyof CompanyProfile as `companyProfile.${P}`]: CompanyProfile[P];
  } &
  {
    [P in keyof Exclusions as `exclusions.${P}`]: Exclusions[P];
  } &
  {
    [P in keyof Geographic as `geographic.${P}`]: Geographic[P];
  } &
  {
    [P in keyof InvalidFields]: InvalidFields[P];
  } & {
    isLoadedCriteria?: boolean;
  };

export interface FlattenedSearchState
  extends BusinessSignals,
    CompanyFinancials,
    CompanyProfile,
    Exclusions,
    Geographic,
    InvalidFields {}

export type SavedSearchesResponseKeys =
  | Exclude<keyof APISearchCriteriaBody, "excludePortfolios">
  | "excludePortfolioIds";

export type APISearchCriteriaBody = {
  countries?: string[];
  creditLimitIncludeNotFiled?: boolean;
  creditScoreIncludeNotFiled?: boolean;
  employeesIncludeNotFiled?: boolean;
  financialAvailable?: boolean;
  incorporationIncludeNotFiled?: boolean;
  marketingAllowed?: boolean;
  phoneAvailable?: boolean;
  postcodeAvailable?: boolean;
  uniqueTelephoneNumber?: boolean;
  excludeHasCCJ?: boolean;
  creditScoreTrend?: string;
  incorporationEndDate?: string;
  incorporationStartDate?: string;
  addressTypes?: string[];
  companyStatus?: string[];
  excludePortfolios?: string[];
  locations?: Location[];
  excludeLocations?: Location[];
  registeredTypes?: string[];
  internationalActivities?: ActivityCodeSearchBody[];
  excludeInternationalActivities?: ActivityCodeSearchBody[];
  localActivities?: ActivityCodeSearchBody[];
  excludeLocalActivities?: ActivityCodeSearchBody[];
  internationalScores?: string[];
  legalForms?: string[];
  businessClassifications?: string[];
  companyAuditors?: string[];
  caExports?: string[];
  postcodes?: string[];
  postCodeSearchType?: string;
  creditLimitMax?: number;
  creditLimitMin?: number;
  creditScoreMax?: number;
  creditScoreMin?: number;
  currentDebtRatioMax?: number;
  currentDebtRatioMin?: number;
  currentRatioMax?: number;
  currentRatioMin?: number;
  dbtMax?: number;
  dbtMin?: number;
  employeesMax?: number;
  employeesMin?: number;
  netWorthMax?: number;
  netWorthMin?: number;
  pretaxProfitMarginMax?: number;
  pretaxProfitMarginMin?: number;
  pretaxProfitMax?: number;
  pretaxProfitMin?: number;
  profitMax?: number;
  profitMin?: number;
  returnCapitalEmployedMax?: number;
  returnCapitalEmployedMin?: number;
  shareCapitalMax?: number;
  shareCapitalMin?: number;
  shareholderFundsMax?: number;
  shareholderFundsMin?: number;
  totalCurrentAssetsMax?: number;
  totalCurrentAssetsMin?: number;
  totalCurrentLiabilitiesMax?: number;
  totalCurrentLiabilitiesMin?: number;
  totalDebtRatioMax?: number;
  totalDebtRatioMin?: number;
  totalFixedAssetsMax?: number;
  totalFixedAssetsMin?: number;
  totalLongTermLiabilitiesMax?: number;
  totalLongTermLiabilitiesMin?: number;
  turnoverMax?: number;
  turnoverMin?: number;
  workingCapitalMax?: number;
  workingCapitalMin?: number;
  activityType?: string;
  currency?: string;
  excludePreviousOrdersDuration?: string;
  directorAgeMax?: number;
  directorAgeMin?: number;
};

export type APISearchCriteriaResponseBody = {
  [P in keyof APISearchCriteriaBody]: APISearchCriteriaBody[P] extends
    | ActivityCodeSearchBody[]
    | undefined
    ? ActivityCode[]
    : APISearchCriteriaBody[P];
};

export type SearchCriteriaKeys = keyof SearchState;

export type NonNullableSearchState = DeepNonNullable<SearchState>;

export type SearchPayload = SearchState;

export type DeleteCriteriaPayload = {
  key: SearchCriteriaKeys;
};

export type InvalidFieldsPayload = {
  [key: string]: string;
};

export type SearchArrayPayload = {
  key: KeysMatching<NonNullableSearchState, string[]>;
  value: string[];
};

export type SearchArrayValuePayload = {
  key: string;
  field: KeysMatching<NonNullableSearchState, string[]>;
};

export type UpdateSearchLocation = {
  key: string;
  isExclude?: boolean;
};

export type UpdateSearchActivityCode = {
  key: string;
  isExclude?: boolean;
};

export type SearchActionTypes = ActionType<typeof SearchActions>;
