/* istanbul ignore file */
import { ajax, AjaxRequest } from "rxjs/ajax";

const request = (request: AjaxRequest) => {
  const ajaxOptions = {
    url: request.url,
    method: request.method,
    headers: {
      ...(request.headers || {}),
      Authorization: `Bearer ${localStorage.accessToken}`,
    },
    body: request.body || {},
  };
  return ajax(ajaxOptions);
};
export default request;
