import request from "./utils/request";
import config from "../config";
import initWalkme, { setWalkMeLanguage } from "@creditsafe/walkme";
import { getAppLanguage } from "../appUtils";

export type RequestType = typeof request;
export interface EpicDependencies {
  localStorage: typeof localStorage;
  config: typeof config;
  request: RequestType;
  initWalkme: typeof initWalkme;
  setWalkMeLanguage: typeof setWalkMeLanguage;
  getAppLanguage: typeof getAppLanguage;
}

export default {
  localStorage,
  config,
  request,
  initWalkme,
  setWalkMeLanguage,
  getAppLanguage,
};
