import { action } from "typesafe-actions";
import {
  FETCH_BUSINESS_CLASSIFICATIONS,
  FETCH_BUSINESS_CLASSIFICATIONS_ERROR,
  FETCH_BUSINESS_CLASSIFICATIONS_SUCCESS,
  CLEAR_HAS_CRITERIA_CHANGED,
  CLEAR_SAVED_SEARCHES,
  DeleteSavedSearchPayload,
  DELETE_SAVED_SEARCH,
  DELETE_SAVED_SEARCH_SUCCESS,
  FetchActivityCodesByNamePayload,
  FetchActivityCodesByParentPayload,
  FetchActivityCodesResponse,
  FetchActivityTypePayload,
  FetchActivityTypeResponse,
  FetchBusinessClassificationPayload,
  FetchBusinessClassificationResponse,
  FetchLegalFormPayload,
  FetchLegalFormsResponse,
  FetchLocationsPayload,
  FetchLocationsResponse,
  FetchSavedSearchesPayload,
  FetchSavedSearchesResponse,
  FETCH_ACTIVITY_CODES_BY_NAME,
  FETCH_ACTIVITY_CODES_BY_NAME_ERROR,
  FETCH_ACTIVITY_CODES_BY_NAME_SUCCESS,
  FETCH_ACTIVITY_CODES_BY_PARENT,
  FETCH_ACTIVITY_CODES_BY_PARENT_ERROR,
  FETCH_ACTIVITY_CODES_BY_PARENT_SUCCESS,
  FETCH_ACTIVITY_CODES_BY_ROOT,
  FETCH_ACTIVITY_CODES_BY_ROOT_ERROR,
  FETCH_ACTIVITY_CODES_BY_ROOT_SUCCESS,
  FETCH_ACTIVITY_TYPE,
  FETCH_ACTIVITY_TYPE_ERROR,
  FETCH_ACTIVITY_TYPE_SUCCESS,
  FETCH_LEGAL_FORMS,
  FETCH_LEGAL_FORMS_ERROR,
  FETCH_LEGAL_FORMS_SUCCESS,
  FETCH_LOCATIONS_BY_NAME,
  FETCH_LOCATIONS_BY_NAME_ERROR,
  FETCH_LOCATIONS_BY_NAME_SUCCESS,
  FETCH_LOCATIONS_BY_PARENT,
  FETCH_LOCATIONS_BY_PARENT_ERROR,
  FETCH_LOCATIONS_BY_PARENT_SUCCESS,
  FETCH_SAVED_SEARCHES,
  FETCH_SAVED_SEARCHES_ERROR,
  FETCH_SAVED_SEARCHES_SUCCESS,
  LoadSavedSearchPayload,
  LOAD_SAVED_SEARCH,
  SaveSearchPayload,
  SAVE_SEARCH,
  SAVE_SEARCH_CLEAR,
  SAVE_SEARCH_ERROR,
  SAVE_SEARCH_SUCCESS,
  FetchCompanyAuditorPayload,
  FETCH_COMPANY_AUDITORS,
  FETCH_COMPANY_AUDITORS_SUCCESS,
  FetchCompanyAuditorResponse,
  FETCH_COMPANY_AUDITORS_ERROR,
} from "./types";

export const fetchLegalForms = (payload: FetchLegalFormPayload) =>
  action(FETCH_LEGAL_FORMS, payload);

export const fetchLegalFormsSuccess = (payload: FetchLegalFormsResponse) =>
  action(FETCH_LEGAL_FORMS_SUCCESS, payload);

export const fetchLegalFormsError = () => action(FETCH_LEGAL_FORMS_ERROR);

export const fetchBusinessClassifications = (
  payload: FetchBusinessClassificationPayload
) => action(FETCH_BUSINESS_CLASSIFICATIONS, payload);

export const fetchBusinessClassificationsSuccess = (
  payload: FetchBusinessClassificationResponse
) => action(FETCH_BUSINESS_CLASSIFICATIONS_SUCCESS, payload);

export const fetchBusinessClassificationsError = () =>
  action(FETCH_BUSINESS_CLASSIFICATIONS_ERROR);

export const fetchCompanyAuditors = (payload: FetchCompanyAuditorPayload) =>
  action(FETCH_COMPANY_AUDITORS, payload);

export const fetchCompanyAuditorsSuccess = (
  payload: FetchCompanyAuditorResponse
) => action(FETCH_COMPANY_AUDITORS_SUCCESS, payload);

export const fetchCompanyAuditorsError = () =>
  action(FETCH_COMPANY_AUDITORS_ERROR);

export const fetchLocationsByName = (payload: FetchLocationsPayload) =>
  action(FETCH_LOCATIONS_BY_NAME, payload);

export const fetchLocationsByNameSuccess = (payload: FetchLocationsResponse) =>
  action(FETCH_LOCATIONS_BY_NAME_SUCCESS, payload);

export const fetchLocationsByNameError = () =>
  action(FETCH_LOCATIONS_BY_NAME_ERROR);

export const fetchLocationsByParent = (payload: FetchLocationsPayload) =>
  action(FETCH_LOCATIONS_BY_PARENT, payload);

export const fetchLocationsByParentSuccess = (
  payload: FetchLocationsResponse
) => action(FETCH_LOCATIONS_BY_PARENT_SUCCESS, payload);

export const fetchLocationsByParentError = () =>
  action(FETCH_LOCATIONS_BY_PARENT_ERROR);

export const fetchSavedSearches = (payload: FetchSavedSearchesPayload) =>
  action(FETCH_SAVED_SEARCHES, payload);

export const fetchSavedSearchesSuccess = (
  payload: FetchSavedSearchesResponse
) => action(FETCH_SAVED_SEARCHES_SUCCESS, payload);

export const fetchSavedSearchesError = () => action(FETCH_SAVED_SEARCHES_ERROR);

export const loadSavedSearch = (payload: LoadSavedSearchPayload) =>
  action(LOAD_SAVED_SEARCH, payload);

export const clearSavedSearches = () => action(CLEAR_SAVED_SEARCHES);

export const deleteSavedSearch = (payload: DeleteSavedSearchPayload) =>
  action(DELETE_SAVED_SEARCH, payload);

export const deleteSavedSearchSuccess = () =>
  action(DELETE_SAVED_SEARCH_SUCCESS, null);

export const saveSearch = (payload: SaveSearchPayload) =>
  action(SAVE_SEARCH, payload);

export const saveSearchSuccess = () => action(SAVE_SEARCH_SUCCESS);

export const saveSearchError = () => action(SAVE_SEARCH_ERROR);

export const clearSaveSearch = () => action(SAVE_SEARCH_CLEAR);

export const clearHasCriteriaChanged = () => action(CLEAR_HAS_CRITERIA_CHANGED);

export const fetchActivityType = (payload: FetchActivityTypePayload) =>
  action(FETCH_ACTIVITY_TYPE, payload);

export const fetchActivityTypeSuccess = (payload: FetchActivityTypeResponse) =>
  action(FETCH_ACTIVITY_TYPE_SUCCESS, payload);

export const fetchActivityTypeError = () => action(FETCH_ACTIVITY_TYPE_ERROR);

export const fetchActivityCodesByRoot = (
  payload: FetchActivityCodesByParentPayload
) => action(FETCH_ACTIVITY_CODES_BY_ROOT, payload);

export const fetchActivityCodesByRootSuccess = (
  payload: FetchActivityCodesResponse
) => action(FETCH_ACTIVITY_CODES_BY_ROOT_SUCCESS, payload);

export const fetchActivityCodesByRootError = () =>
  action(FETCH_ACTIVITY_CODES_BY_ROOT_ERROR);

export const fetchActivityCodesByParent = (
  payload: FetchActivityCodesByParentPayload
) => action(FETCH_ACTIVITY_CODES_BY_PARENT, payload);

export const fetchActivityCodesByParentSuccess = (
  payload: FetchActivityCodesResponse
) => action(FETCH_ACTIVITY_CODES_BY_PARENT_SUCCESS, payload);

export const fetchActivityCodesByParentError = () =>
  action(FETCH_ACTIVITY_CODES_BY_PARENT_ERROR);

export const fetchActivityCodesByName = (
  payload: FetchActivityCodesByNamePayload
) => action(FETCH_ACTIVITY_CODES_BY_NAME, payload);

export const fetchActivityCodesByNameSuccess = (
  payload: FetchActivityCodesResponse
) => action(FETCH_ACTIVITY_CODES_BY_NAME_SUCCESS, payload);

export const fetchActivityCodesByNameError = () =>
  action(FETCH_ACTIVITY_CODES_BY_NAME_ERROR);
