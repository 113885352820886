import { RESET_SEARCH, SearchActionTypes } from "../search/types";
import {
  FETCH_PORTFOLIOS_SUCCESS,
  PortfoliosActionTypes,
  PortfoliosState,
} from "./types";

export const initialState: PortfoliosState = {
  portfolios: [],
};

export default function (
  state = initialState,
  action: PortfoliosActionTypes | SearchActionTypes
): PortfoliosState {
  switch (action.type) {
    case FETCH_PORTFOLIOS_SUCCESS: {
      const currentPortfolios = state.portfolios;
      const currentPorfolioIds = new Set(currentPortfolios.map((p) => p.id));
      const newPortfolios = action.payload.portfolios;

      return {
        ...state,
        portfolios: currentPortfolios
          .concat(newPortfolios.filter((p) => !currentPorfolioIds.has(p.id)))
          .sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          ),
      };
    }
    case RESET_SEARCH: {
      return {
        ...state,
        portfolios: [],
      };
    }
    default:
      return state;
  }
}
