import {
  SavedSearchesResponseKeys,
  SearchCategories,
  SearchState,
} from "../store/search/types";

export type AllSearchFilterKeys =
  | BusinessSignalsKeysType[number]
  | FinancialKeysType[number]
  | CompanyProfileKeysType[number]
  | ExclusionKeysType[number];

export const businessSignalsFields = [
  "creditLimitIncludeNotFiled",
  "creditLimitMin",
  "creditLimitMax",
  "creditScoreIncludeNegativeScores",
  "creditScoreIncludeNotFiled",
  "creditScoreMin",
  "creditScoreMax",
  "creditScoreTrend",
  "internationalScores",
  "creditScoreTrendsIncrease",
] as const;

type BusinessSignalsKeysType = typeof businessSignalsFields;

export const companyProfileFields = [
  "addressTypes",
  "companyStatus",
  "dbt",
  "employeesIncludeNotFiled",
  "employees",
  "incorporation",
  "incorporationIncludeNotFiled",
  "internationalActivities",
  "legalForms",
  "localActivities",
  "registeredTypes",
  "caExports",
] as const;

type CompanyProfileKeysType = typeof companyProfileFields;

export const geographicFields = [
  "locations",
  "excludeLocations",
  "postCodeSearchType",
  "postcodes",
] as const;

export const companyFinancialFields = [
  "currency",
  "turnover",
  "pretaxProfit",
  "profit",
  "totalFixedAssets",
  "totalCurrentAssets",
  "totalCurrentLiabilities",
  "totalLongTermLiabilities",
  "shareholderFunds",
  "netWorth",
  "workingCapital",
  "currentRatio",
  "pretaxProfitMargin",
  "returnCapitalEmployed",
  "totalDebtRatio",
  "currentDebtRatio",
  "shareCapital",
] as const;

type FinancialKeysType = typeof companyFinancialFields;
type MinMaxFinancialFields = Exclude<FinancialKeysType[number], "currency">;
type FinancialKey =
  `companyFinancials.${MinMaxFinancialFields}${NumberRangeMinMax}`;

export const toFinancialKey = (
  field: FinancialKeysType[number],
  minMax: NumberRangeMinMax
) => ("companyFinancials." + field + minMax) as FinancialKey;

export const exclusionFields = [
  "marketingAllowed",
  "phoneAvailable",
  "postcodeAvailable",
  "financialAvailable",
  "uniqueTelephoneNumber",
  "excludeHasCCJ",
] as const;

type ExclusionKeysType = typeof exclusionFields;
type ExclusionKey = `exclusions.${ExclusionKeysType[number]}`;

export const toExclusionKey = (field: ExclusionKeysType[number]) =>
  ("exclusions." + field) as ExclusionKey;

export const numberRangeCriteria = [
  "companyFinancials.turnover",
  "companyFinancials.pretaxProfit",
  "companyFinancials.profit",
  "companyFinancials.totalFixedAssets",
  "companyFinancials.totalCurrentAssets",
  "companyFinancials.totalCurrentLiabilities",
  "companyFinancials.totalLongTermLiabilities",
  "companyFinancials.shareholderFunds",
  "companyFinancials.netWorth",
  "companyFinancials.workingCapital",
  "companyFinancials.currentRatio",
  "companyFinancials.pretaxProfitMargin",
  "companyFinancials.returnCapitalEmployed",
  "companyFinancials.totalDebtRatio",
  "companyFinancials.currentDebtRatio",
  "companyFinancials.shareCapital",
  "companyProfile.dbt",
] as const;

export const dateRangeCriteria = ["companyProfile.incorporation"] as const;

export const maxRangeValue: {
  key: NumberRangeCriteria[number];
  value: number;
}[] = [{ key: "companyFinancials.currentRatio", value: 999 }];

export const getMaxRangeValue = (key: NumberRangeCriteria[number]) =>
  maxRangeValue.filter((k) => k.key === key)[0]?.value;

export type NumberRangeCriteria = typeof numberRangeCriteria;
type NumberRangeMinMax = "Min" | "Max";
type NumberRangeMinMaxSearchCriteria =
  `${NumberRangeCriteria[number]}${NumberRangeMinMax}`;

export const toMinMaxKey = (
  criteria: NumberRangeCriteria[number],
  minMax: NumberRangeMinMax
) => (criteria + minMax) as NumberRangeMinMaxSearchCriteria;

export type DateRangeCriteria = typeof dateRangeCriteria;
type DateRangeStartEnd = "StartDate" | "EndDate";
type DateRangeMinMaxSearchCriteria =
  `${DateRangeCriteria[number]}${DateRangeStartEnd}`;

export const toStartEndDateKey = (
  criteria: DateRangeMinMaxSearchCriteria[number],
  minMax: DateRangeStartEnd
) => (criteria + minMax) as DateRangeMinMaxSearchCriteria;

export const toFilterCriteriaKey = (
  category: SearchCategories,
  filter: AllSearchFilterKeys
) => `${category}.${filter}` as keyof SearchState;

export const mapCriteriaToCategory: Partial<
  {
    [Property in SavedSearchesResponseKeys]: keyof SearchState;
  }
> = {
  postcodes: "geographic.postcodes",
  postCodeSearchType: "geographic.postCodeSearchType",
  currency: "companyFinancials.currency",
  turnoverMin: "companyFinancials.turnoverMin",
  turnoverMax: "companyFinancials.turnoverMax",
  pretaxProfitMin: "companyFinancials.pretaxProfitMin",
  pretaxProfitMax: "companyFinancials.pretaxProfitMax",
  profitMin: "companyFinancials.profitMin",
  profitMax: "companyFinancials.profitMax",
  totalFixedAssetsMin: "companyFinancials.totalFixedAssetsMin",
  totalFixedAssetsMax: "companyFinancials.totalFixedAssetsMax",
  totalCurrentAssetsMin: "companyFinancials.totalCurrentAssetsMin",
  totalCurrentAssetsMax: "companyFinancials.totalCurrentAssetsMax",
  totalCurrentLiabilitiesMin: "companyFinancials.totalCurrentLiabilitiesMin",
  totalCurrentLiabilitiesMax: "companyFinancials.totalCurrentLiabilitiesMax",
  totalLongTermLiabilitiesMin: "companyFinancials.totalLongTermLiabilitiesMin",
  totalLongTermLiabilitiesMax: "companyFinancials.totalLongTermLiabilitiesMax",
  shareholderFundsMin: "companyFinancials.shareholderFundsMin",
  shareholderFundsMax: "companyFinancials.shareholderFundsMax",
  netWorthMin: "companyFinancials.netWorthMin",
  netWorthMax: "companyFinancials.netWorthMax",
  workingCapitalMin: "companyFinancials.workingCapitalMin",
  workingCapitalMax: "companyFinancials.workingCapitalMax",
  currentRatioMin: "companyFinancials.currentRatioMin",
  currentRatioMax: "companyFinancials.currentRatioMax",
  pretaxProfitMarginMin: "companyFinancials.pretaxProfitMarginMin",
  pretaxProfitMarginMax: "companyFinancials.pretaxProfitMarginMax",
  returnCapitalEmployedMin: "companyFinancials.returnCapitalEmployedMin",
  returnCapitalEmployedMax: "companyFinancials.returnCapitalEmployedMax",
  totalDebtRatioMin: "companyFinancials.totalDebtRatioMin",
  totalDebtRatioMax: "companyFinancials.totalDebtRatioMax",
  currentDebtRatioMin: "companyFinancials.currentDebtRatioMin",
  currentDebtRatioMax: "companyFinancials.currentDebtRatioMax",
  shareCapitalMin: "companyFinancials.shareCapitalMin",
  shareCapitalMax: "companyFinancials.shareCapitalMax",
  internationalScores: "businessSignals.internationalScores",
  marketingAllowed: "exclusions.marketingAllowed",
  phoneAvailable: "exclusions.phoneAvailable",
  postcodeAvailable: "exclusions.postcodeAvailable",
  financialAvailable: "exclusions.financialAvailable",
  companyStatus: "companyProfile.companyStatus",
  incorporationStartDate: "companyProfile.incorporationStartDate",
  incorporationEndDate: "companyProfile.incorporationEndDate",
  incorporationIncludeNotFiled: "companyProfile.incorporationIncludeNotFiled",
  dbtMin: "companyProfile.dbtMin",
  dbtMax: "companyProfile.dbtMax",
  employeesMin: "companyProfile.employeesMin",
  employeesMax: "companyProfile.employeesMax",
  employeesIncludeNotFiled: "companyProfile.employeesIncludeNotFiled",
  excludePortfolioIds: "exclusions.excludePortfolios",
  excludePreviousOrdersDuration: "exclusions.excludePreviousOrdersDuration",
  creditScoreMin: "businessSignals.creditScoreMin",
  creditScoreMax: "businessSignals.creditScoreMax",
  creditScoreIncludeNotFiled: "businessSignals.creditScoreIncludeNotFiled",
  creditLimitMin: "businessSignals.creditLimitMin",
  creditLimitMax: "businessSignals.creditLimitMax",
  creditLimitIncludeNotFiled: "businessSignals.creditLimitIncludeNotFiled",
  registeredTypes: "companyProfile.registeredTypes",
  addressTypes: "companyProfile.addressTypes",
  legalForms: "companyProfile.legalForms",
  businessClassifications: "companyProfile.businessClassifications",
  companyAuditors: "companyProfile.companyAuditors",
  caExports: "companyProfile.caExports",
  uniqueTelephoneNumber: "exclusions.uniqueTelephoneNumber",
  creditScoreTrend: "businessSignals.creditScoreTrend",
  activityType: "companyProfile.activityType",
  directorAgeMin: "companyProfile.directorAgeMin",
  directorAgeMax: "companyProfile.directorAgeMax",
  excludeHasCCJ: "exclusions.excludeHasCCJ",
};
