import { Epic } from "../rootEpic";
import { concat, of } from "rxjs";
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from "rxjs/operators";
import { ActionType, isOfType } from "typesafe-actions";
import { setAppLanguage } from "../../appUtils";
import { setLocalesAction } from "../i18n/actions";
import {
  fetchAdminUserErrorAction,
  fetchAdminUserSuccessAction,
  fetchUserCreditsError,
  fetchUserCreditsSuccess,
  fetchUserDetailsErrorAction,
  fetchUserDetailsSuccessAction,
  fetchUserEntityDetailsErrorAction,
  fetchUserEntityDetailsSuccessAction,
  putUserLangErrorAction,
  putUserLangSuccessAction,
} from "./actions";
import {
  FETCH_ADMIN_USER,
  FETCH_USER_CREDITS,
  FETCH_USER_DETAILS,
  FETCH_USER_DETAILS_SUCCESS,
  PUT_USER_LANG,
} from "./types";

export const fetchUserDetailsEpic: Epic<
  | ActionType<typeof fetchUserDetailsSuccessAction>
  | ActionType<typeof fetchUserDetailsErrorAction>
> = (action$, _$, { request, config, initWalkme }) =>
  action$.pipe(
    filter(isOfType(FETCH_USER_DETAILS)),
    mergeMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.UPP_API_URL}/users/${payload}`,
      }).pipe(
        switchMap(({ response }) => {
          setAppLanguage(response.languageCode);
          initWalkme({
            initialLanguage: response.languageCode,
            walkmeSrc: config.WALKME_SRC,
          });
          return of(fetchUserDetailsSuccessAction(response));
        }),
        catchError(() => of(fetchUserDetailsErrorAction()))
      )
    )
  );

export const fetchAdminUserEpic: Epic<
  | ActionType<typeof fetchAdminUserSuccessAction>
  | ActionType<typeof fetchAdminUserErrorAction>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_ADMIN_USER)),
    mergeMap(({}) =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/admin/isAdmin`,
      }).pipe(
        switchMap(({ response }) => {
          return of(fetchAdminUserSuccessAction(response));
        }),
        catchError(() => of(fetchAdminUserErrorAction()))
      )
    )
  );
export const fetchUserEntityDetailsEpic: Epic<
  | ActionType<typeof fetchUserEntityDetailsSuccessAction>
  | ActionType<typeof setLocalesAction>
  | ActionType<typeof fetchUserEntityDetailsErrorAction>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_USER_DETAILS_SUCCESS)),
    switchMap(({ payload }) =>
      request({
        method: "GET",
        url: `${config.UPP_UBO_URL}/Reference/entityinfo/${payload.countryCode}`,
      }).pipe(
        switchMap(({ response }) => {
          const { email, tel, entityName, address, locales } = response;
          const mappedLocale = locales.map((locale: string) => ({
            code: locale,
          }));

          return of(
            setLocalesAction(mappedLocale),
            fetchUserEntityDetailsSuccessAction({
              email,
              tel,
              entityName,
              address,
            })
          );
        }),
        catchError(() => of(fetchUserEntityDetailsErrorAction()))
      )
    )
  );

export const putUserLangEpic: Epic<
  | ActionType<typeof putUserLangSuccessAction>
  | ActionType<typeof putUserLangErrorAction>
> = (action$, state$, { request, config, setWalkMeLanguage }) =>
  action$.pipe(
    filter(isOfType(PUT_USER_LANG)),
    withLatestFrom(state$),
    switchMap(([{ payload }, { user }]) =>
      concat(
        request({
          method: "PUT",
          url: `${config.UPP_UBO_URL}/users/${user.userId}/locale?locale=${payload}`,
        }),
        request({
          url: `${config.UPP_API_URL}/users/${user.userId}`,
          method: "GET",
          headers: {
            /* 
              The users endpoint is cached by the UPP_API. Here we force the cache to invalidate, 
              so that the API returns the updated language for future requests.
            */
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
          },
        })
      ).pipe(
        switchMap(() => {
          setWalkMeLanguage(payload);
          return of(putUserLangSuccessAction());
        }),
        catchError(() => of(putUserLangErrorAction()))
      )
    )
  );

export const fetchUserCreditsEpic: Epic<
  | ActionType<typeof fetchUserCreditsSuccess>
  | ActionType<typeof fetchUserCreditsError>
> = (action$, _$, { request, config }) =>
  action$.pipe(
    filter(isOfType(FETCH_USER_CREDITS)),
    switchMap(() =>
      request({
        method: "GET",
        url: `${config.PROSPECTS_API_URL}/users/credits`,
      }).pipe(
        map(({ response }) => fetchUserCreditsSuccess(response)),
        catchError(() => of(fetchUserCreditsError()))
      )
    )
  );
